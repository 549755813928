import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PaginationNav extends Component {

    moveToPage(e) {
        this.props.moveToPage(parseInt(e.target.innerText));
    }
    moveToFirstPage() {
        this.props.moveToPage(1);
    }
    moveToLastPage() {
        this.props.moveToPage(this.props.total_page);
    }
    render() {
        const {total_page, activePage} = this.props;
        return (
            <div>
                <div className="b-page">
                    <ul className="pagination justify-content-center" id="pagiNav">
                        {activePage !== 1? <Link className="page-link"  onClick={() => this.moveToFirstPage()}>Trang đầu</Link>:null}
                        {activePage>= 4? <li className="page-item">. . .</li> : null}
                        {activePage - 2 <= 0 ? null : <li className="page-item"><Link className="page-link"  onClick={(event) => this.moveToPage(event)}>{activePage - 2}</Link></li>}
                        {activePage - 1 <= 0 ? null : <li className="page-item" ><Link className="page-link" onClick={(event) => this.moveToPage(event)}>{activePage - 1}</Link></li>}
                        {total_page>1? <li className="page-item active"><span className="page-link">{activePage}</span></li>: null}
                        {activePage + 1 > total_page ? null : <li className="page-item"><Link className="page-link"  onClick={(event) => this.moveToPage(event)}>{activePage + 1}</Link></li>}
                        {activePage + 2 > total_page ? null : <li className="page-item"><Link className="page-link" onClick={(event) => this.moveToPage(event)}>{activePage + 2}</Link></li>}
                        {activePage <= total_page-3? <li className="page-item">. . .</li> : null}
                        {activePage !== total_page ? <Link className="page-link"  onClick={() => this.moveToLastPage()}>Trang cuối</Link> : null}
                    </ul>
                    </div>
            </div>
        );
    }
}

export default PaginationNav;