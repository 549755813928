import React, { Component } from 'react'
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx';
import challenges from "../../lib/libraries/challenge/challenges-list.json";
import ComponentItemsLoading from '../project-featured/component-featured/item-loading.jsx';
import { withRouter } from 'react-router-dom';
import ArticleComponent from '../../components/codekitten/tutorials-pagination/article-component.jsx';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx';
class Challenges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            challengesList: [],
            loading: true
        }
    }
    componentDidMount() {
        window.scroll(0, 0);
        this.setState({
            challengeList: challenges,
            loading: false
        })
    }

    render() {
        return (
            <>
            <SeoComponent title='Thử thách' url={`${window.location.href}`}/>
                <HomeTopic name="Thử thách" />
                <div className="l-content">
                    <div className="container">
                        <div className="c-knowledge-grid">
                            {!this.state.loading ?
                                (<ul>
                                    {this.state.challengeList.map((challenge) => (
                                        <li key={challenge.index}>
                                            <ArticleComponent onClick={() => this.props.history.push("/challenges/" + challenge.url)}
                                                title={challenge.name} description={challenge.description} thumbnail={challenge.thumbnail} />
                                        </li>
                                    ))}
                                </ul>)
                                :
                                (<ul id=""><li>
                                    <ComponentItemsLoading></ComponentItemsLoading>
                                </li>
                                    <li>
                                        <ComponentItemsLoading></ComponentItemsLoading>
                                    </li>
                                    <li>
                                        <ComponentItemsLoading></ComponentItemsLoading>
                                    </li> </ul>)}
                        </div>
                    </div>
                    {/*end container*/}
                </div>
            </>
        )
    }
}
export default withRouter(Challenges);
