import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import RequestButton from '../../../components/codekitten/request-btn/request-btn.jsx';
import Timer from '../../../components/codekitten/countdownTimer/countdown-timer.jsx';

class ForgotpassSecondStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTimerEnded: false,
            basicError: ""
        }
        this.EndUpCountdown = this.EndUpCountdown.bind(this);
        this.submitOTP = this.submitOTP.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
        this.clear_OTP_input = this.clear_OTP_input.bind(this);
    }

    get_OTP_input() {
        let sms_code = "";
        for (var i = 0; i <= 5; i++) {
            let value = document.getElementById('otp_forgot_input_' + i.toString()).value;
            sms_code = sms_code + value.toString();
        }
        return sms_code;
    }

    clear_OTP_input() {
        for (var i = 0; i <= 5; i++) {
            document.getElementById('otp_forgot_input_' + i.toString()).value = "";
        }
        document.getElementById("otp_forgot_input_0").focus();
    }

    EndUpCountdown() {
        this.setState({
            isTimerEnded: true
        });
    }

    // functions to make a better input
    onChangeInput(e) {
        const currentField = e.target;
        if (currentField.value === " ") {
            currentField.value = "";
        }
        var currentId = currentField.id.substring(currentField.id.length - 1, currentField.id.length);
        currentId = parseInt(currentId);
        if (currentField.value.length == 1) {
            if (!(currentId == 5)) {
                document.getElementById('otp_forgot_input_' + (currentId + 1)).focus();
            }
        }
        if (currentField.value.length == 0) {
            if (!(currentId == 0)) {
                document.getElementById('otp_forgot_input_' + (currentId - 1)).focus();
            }
        }
    }

    resendOTP() {
        this.props.resendOTP();
        this.clear_OTP_input();
        this.setState({
            isTimerEnded: false,
            basicError: ""
        })
    }

    submitOTP() {
        let sms_code = this.get_OTP_input();

        if(sms_code.length < 6) {
            this.setState({
                basicError: "Vui lòng nhập đầy đủ mã!"
            });
            return;
        }
        this.setState({
            basicError: ""
        });

        this.props.submitSecondStep(sms_code);
    }

    render() {
        return (
            <div className="c-auth-form">
                <div className="form-group">
                    <div className="c-auth-box__otp">
                        <h6>Xác thực tài khoản</h6><i className="icon32" />
                        <p>OTP đã được gửi đến số điện thoại</p>
                        <p><b>{this.props.countryCode + " " + this.props.phonenumber}</b></p>
                        <p className="is-small mt-2"><b><Link onClick={() => this.props.backToFirstStep()}>Đổi số điện thoại khác</Link></b></p>
                    </div>
                </div>
                <div className="form-group">
                    <div className="c-otp-number">
                        <ul>
                            <li>
                                <input id="otp_forgot_input_0" className="form-control" type="tel" maxLength={1} onChange={event => this.onChangeInput(event)} />
                            </li>
                            <li>
                                <input id="otp_forgot_input_1" className="form-control" type="tel" maxLength={1} onChange={event => this.onChangeInput(event)} />
                            </li>
                            <li>
                                <input id="otp_forgot_input_2" className="form-control" type="tel" maxLength={1} onChange={event => this.onChangeInput(event)} />
                            </li>
                            <li>
                                <input id="otp_forgot_input_3" className="form-control" type="tel" maxLength={1} onChange={event => this.onChangeInput(event)} />
                            </li>
                            <li>
                                <input id="otp_forgot_input_4" className="form-control" type="tel" maxLength={1} onChange={event => this.onChangeInput(event)} />
                            </li>
                            <li>
                                <input id="otp_forgot_input_5" className="form-control" type="tel" maxLength={1} onChange={event => this.onChangeInput(event)} />
                            </li>
                        </ul>
                        {this.state.isTimerEnded ? null :
                         (<p className="small mt-1">
                                Thời gian còn&nbsp;
                                <b className="text-danger"><Timer initialMinute={2} initialSeconds={0} EndUpCountdown={this.EndUpCountdown}></Timer></b>
                            </p>) }
                    </div>
                    <div className="text-danger">{this.state.basicError}</div>
                    <div className="text-danger">{this.props.error !== "" && this.state.isTimerEnded? "mã OTP sai hoặc đã hết hạn!" : ""}</div>
                    <div className="text-danger">{this.props.error !== "" && !this.state.isTimerEnded? "mã OTP sai!" : ""}</div>
                </div>
                {this.state.isTimerEnded? 
                (<div className="form-group is-button">
                <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={this.resendOTP}><span>Gửi lại mã OTP</span> </button>
                </div>) : null}
                <div className="form-group is-button">
                    <RequestButton waitingForResponse={this.props.waitingForResponse} content="Xác thực số điện thoại" onClick={this.submitOTP} />
                </div>
            </div>
        )
    }
}
export default ForgotpassSecondStep;
