import React, { Component } from 'react';
import Teacher from "./teacher-component.jsx";

import TeacherList from "../../../../src/lib/libraries/homepage/teachers.json";
import CtaButton from '../../../components/codekitten/cta-button.jsx';

class Teachers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teachers: []
        }
    }
    componentDidMount() {
        this.setState({
            teachers: TeacherList
        })
    }
    render() {
        return (
            <div className="c-block c-free-block">
            <div className="container">
              <div className="c-free-block__people" />
              <div className="c-box">
                <div className="c-box__title is-button">
                  <div className="c-box__title__name"><span className="is-small">Hoàn toàn</span><br /><b>Miễn phí</b></div>
                  <div className="c-box__title__desc">Hỗ trợ bởi các thầy cô nhiều năm giảng dạy công nghệ</div>
                  <CtaButton/>
                </div>
                <div className="c-box__content">
                  <div className="c-free-grid">
                    <ul>
                            {this.state.teachers.map((teacher, index) => (
                        <li key={index} >
                        <Teacher name={teacher.full_name} job={teacher.job} short_description={teacher.short_description}
                            link_img={teacher.link_img} link_facebook={teacher.link_facebook}/>
                        </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*end container*/}
          </div>
        );
    }
}

export default Teachers;