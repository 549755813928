import React, { Component } from 'react'
import CtaButton from '../../../components/codekitten/cta-button.jsx'

export default class HomeInspired extends Component {
    render() {
        return (
            <div className="c-block c-inspired-block">
            <div className="container">
                <div className="c-inspired">
                <div className="c-inspired__content">
                    <div className="c-box">
                    <div className="c-box__title">
                        <div className="c-box__title__name"><span className="is-small">Nhân vật<i className="icon32-heart-red" /></span><br /><b>Truyền cảm hứng</b></div>
                        <div className="c-box__title__desc">
                        <p>
                        Là nhân vật đặc biệt trong Chương trình Cất cánh VTV1 số tháng 1/2021, Minh Quân đã ghi lại ấn tượng đặc biệt cho khán giả truyền hình cả nước. Với 5 năm học lập trình Teky, bạn nhí này đã tạo ra trên 60 ứng dụng phần mềm và đạt được nhiều thành tích, giải thưởng công nghệ trong nước, quốc tế ngay từ khi mới 7 tuổi. Minh Quân đặc biệt ưa thích nền tảng Codekitten vì khả năng lập trình dễ dàng và tạo ra nhiều game đẹp mắt.
                        </p><br />
                        <h4>
                            Học sinh&nbsp;
                            <b>Nguyễn Ngọc Minh Quân</b>
                        </h4>
                        <p className="is-small"><b>
                            Nhân vật&nbsp;
                            <span className="text-warning">Cất cánh VTV tháng 1/2021</span></b></p>
                        </div>
                        <CtaButton />
                    </div>
                    </div>
                </div>
                <div className="c-inspired__img"><a href="https://www.facebook.com/watch/?v=1201968020256322" target="_blank"><img src="/static/upload/inspired.png" alt="inspired" /></a></div>
                </div>
            </div>
            {/*end container*/}
            </div>
        )
    }
}
