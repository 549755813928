import contestApi from "../../../api/contest-api"
import { GET_ACTIVE_CONTESTS_REQUEST, GET_ACTIVE_CONTESTS_SUCCESS, GET_ACTIVE_CONTESTS_FAIL } from "../constants/constants"

export const getActiveContests = () => (dispatch) => {
    dispatch(getActiveContestsRequest())
    contestApi.getActiveContestList().then(response => {
        if(response.message.status_code == 200){ 
            dispatch(getActiveContestsSucces(response.data.contests))
        }
    }).catch(error => {
        console.log(error)
        dispatch(getActiveContestsFail())
    }) 
}

const getActiveContestsRequest = () => ({
    type: GET_ACTIVE_CONTESTS_REQUEST,
})

const getActiveContestsSucces = (contests) => ({
    type: GET_ACTIVE_CONTESTS_SUCCESS,
    data: {contests: contests}

})

const getActiveContestsFail = () => ({
    type: GET_ACTIVE_CONTESTS_FAIL,
})