
import PropTypes from 'prop-types';
import  React  from 'react';
import { Markup } from 'interweave';
import { Link } from 'react-router-dom';


const HighlightProjectItem = props => {
    return (
        <div className="b-grid"  style={{cursor: "pointer"}} onClick={props.onClick} >
          <div className="b-grid__img"><Link ><img src={props.srcImage == null || props.srcImage === ""? "/static/images/thumbnails/default-thumbnail.png" : props.srcImage} alt="image" /></Link></div>
          <div className="b-grid__content">
            <div className="b-grid__row">
              <h3 className="b-grid__title"><Link>{props.title}</Link></h3><span className="b-grid__author">{props.author}</span>
            </div>
            <div className="b-grid__row b-grid__desc"><Markup content={props.desc} /></div>
          </div>
        </div>
  
    );
};

HighlightProjectItem.propTypes = {
    srcImage:PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    desc: PropTypes.string,
    onClick: PropTypes.func,

};
// class HighlightProjectItem extends Component {
  
// }


export default HighlightProjectItem;