import { GET_TUTORIALS_IN_PAGE_REQUEST, GET_TUTORIALS_IN_PAGE_SUCCESS, GET_TUTORIALS_IN_PAGE_FAIL } from "../constants/constants";
import tutorialsApi from "../../../api/tutorials-api";
export const getTutorialsInpage = (page_num) => (dispatch) => {
    dispatch(getTutorialsRequest());
    tutorialsApi.getTutorialsList(page_num, process.env.TUTORIALS_PER_PAGE).then(response => {
        const status = response.message.status;
        if(status === "Success") {
            console.log(response.data.total_page == 1)
            dispatch(getTutorialsSuccess(response.data.current_page, response.data.total_page, response.data.tutorials))
        } else if(status === "Fail") {
            dispatch(getTutorialsFail("Can't load tutorials"))
        }
    }).catch(error => {
        dispatch(getTutorialsFail("Can't load tutorials"))
    })
}

const getTutorialsRequest = () => ({
    type: GET_TUTORIALS_IN_PAGE_REQUEST
})

const getTutorialsSuccess = (currentPage, totalPage, tutorials) => ({
    type: GET_TUTORIALS_IN_PAGE_SUCCESS,
    data: {
        currentPage: currentPage,
        totalPage: totalPage,
        tutorials: tutorials
    }
})

const getTutorialsFail = (error) => ({
    type: GET_TUTORIALS_IN_PAGE_FAIL,
    data: {error: error}
})