import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from "../project-featured.css"

const ItemProjectBestComponent = ({

  href,
  srcImg,
  isActive,
  onClick,


  ...props
}) => {
  return (

    <li onClick={onClick} className={isActive ? "active" : ''}><Link >
      <figure id={style.ItemProjectBestComponentImg} style={{backgroundImage: srcImg === "" || srcImg == null ? "url(/static/images/thumbnails/default-thumbnail.png)" : `url(${srcImg})`}}>
        {/* <img src={srcImg === "" || srcImg == null ? "/static/images/thumbnails/default-thumbnail.png" : srcImg} alt="project" /> */}
      </figure></Link></li>


  );
}
ItemProjectBestComponent.prototype = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func.isRequired
  ]),
  srcImg: PropTypes.oneOfType([
    PropTypes.string.isRequired,
  ]),
  isActive: PropTypes.bool
};




export default ItemProjectBestComponent;