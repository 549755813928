import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ComponentError extends Component {
    render() {
        return (
            <div className="c-box is-margin-medium">
                <div className="c-box__title is-button">
                    <h2 className="c-box__title__name">
                        <span className="is-small">Dự án</span>
                        <br />
                        <b>
                            {this.props.title}
                        </b>
                    </h2>
                    <div className="c-box__title__btn">
                        <a className="btn btn-primary is-circle">
                            <b>Xem toàn bộ</b>
                        </a>
                    </div>
                </div>
                <div className="c-box__content">
                    <div>Hiện chưa có dữ liệu.</div>
                </div>
            </div>
        );
    }
}
ComponentError.propTypes = {
    title: PropTypes.string.isRequired,
};

export default ComponentError;