import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ForgotpassFirstStep from './forgotpass-forms/forgotpass-step1.jsx';
import ForgotpassSecondStep from './forgotpass-forms/forgotpass-step2.jsx';
import ForgotpassLastStep from './forgotpass-forms/forgotpass-step3.jsx';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPasswordByPhone, verifyOTPForgotPass, changeForgotPassPhonenumber, clearForgotRedux } from "../../reducers/codekitten/forgot-password-reducer/forgot-password-actions";
import authApi from '../../api/auth-api.js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorDialog from './dialog/alert-dialog.jsx';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx';
class Forgotpass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            showErrorDialog: false
        }
        //a
        this.submitFirstStep = this.submitFirstStep.bind(this);
        this.submitSecondStep = this.submitSecondStep.bind(this);
        this.submitLastStep = this.submitLastStep.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
        this.backToFirstStep = this.backToFirstStep.bind(this);

    }

    submitFirstStep(phonenumber) {
        this.props.forgotPasswordByPhone(this.props.countryCode, phonenumber);
    }

    backToFirstStep() {
        this.props.changeForgotPassPhonenumber();
    }

    resendOTP() {
        this.props.forgotPasswordByPhone(this.props.countryCode, this.props.phonenumber);
    }

    submitSecondStep(sms_code) {
        this.props.verifyOTPForgotPass(this.props.countryCode + this.props.phonenumber, sms_code);
    }

    submitLastStep(newPassword) {
        console.log(newPassword)
        authApi.setPasswordForgotPW(this.props.forgot_token, newPassword).then(response => {
            const status_code = response.message.status_code;
            if (status_code == 200) {
                this.setState({
                    showDialog: true
                })
            } else {
                this.setState({
                    showErrorDialog: true
                })
            }
        }).catch(error => {
            this.setState({
                showErrorDialog: true
            })
        })
    }

    toLogin = () => {
        this.props.history.push("/login")
    }

    closeErrorDialog = () => {
        this.setState({
            showErrorDialog: false
        })
    }

    componentDidMount() {
        this.props.clearForgotRedux()
        if (this.props.onStep != 1 ) {
            this.props.changeForgotPassPhonenumber();   
        }
        if (this.props.loggedIn)
            this.props.history.push("/");
    }

    render() {
        const { countryCode, phonenumber, onStep, waitingForResponse, error } = this.props;
        return (
            <>
                <SeoComponent title="Quên mật khẩu"/>
                <div className="bg-user-full" />
                <div className="c-auth-center">
                    <div className="c-auth-box">
                        <div className="c-auth-box__left">
                            <div className="c-auth-box__logo">
                                <Link to='/'>
                                    <img src="/static/images/logo-black.svg" alt="logo" />
                                </Link>
                            </div>
                            {onStep == 1 ? <ForgotpassFirstStep waitingForResponse={waitingForResponse} submitFirstStep={this.submitFirstStep}
                                error={error} phonenumber={phonenumber} />
                                : null}
                            {onStep == 2 ? <ForgotpassSecondStep phonenumber={phonenumber} countryCode={countryCode}
                                waitingForResponse={waitingForResponse} error={error}
                                backToFirstStep={this.backToFirstStep}
                                resendOTP={this.resendOTP} submitSecondStep={this.submitSecondStep} />
                                : null}

                            {onStep == 3 ? <ForgotpassLastStep submitLastStep={this.submitLastStep} lastStateError={this.state.lastStateError} />
                                : null}
                            <div className="c-auth-box__copyright">
                                Made with&nbsp;
                                <i className="icon16-heart-red" />
                                &nbsp;by TEKY
                            </div>
                        </div>
                        <div className="c-auth-box__right bg-primary">
                            <div className="c-auth-box__info">
                                <h1>Oh, Bạn bị quên mật khẩu?</h1>
                                <p>Điền thông tin để chúng tôi giúp bạn nhé</p>
                            </div>
                            <div className="c-auth-box__img"><img src="/static/images/kitten-auth.png" alt="kitten" /></div>
                        </div>
                    </div>
                </div>

                <Dialog
                    open={this.state.showDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Đổi mật khẩu thành công!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Bạn đã đổi mật khẩu thành công, ấn 'Hoàn Thành' để tới trang đăng nhập.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toLogin} color="primary">
                            Hoàn Thành
                        </Button>
                    </DialogActions>
                </Dialog>

                <ErrorDialog open={this.state.showErrorDialog} onClick={this.closeErrorDialog}
                    title="Lỗi"
                    description="Đã có lỗi xảy ra, vui lòng thử lại sau!" />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn, // lấy tình trạng đăng nhập của người dùng
    countryCode: state.forgot.countryCode,
    phonenumber: state.forgot.phonenumber,
    onStep: state.forgot.onStep,
    forgot_token: state.forgot.forgot_token,
    waitingForResponse: state.forgot.waitingForResponse,
    error: state.forgot.error
});

const mapDispatchToProps = (dispatch) => {
    return {
        forgotPasswordByPhone: (countryCode, phonenumber) => dispatch(forgotPasswordByPhone(countryCode, phonenumber)),
        verifyOTPForgotPass: (phonenumber, sms_code) => dispatch(verifyOTPForgotPass(phonenumber, sms_code)),
        changeForgotPassPhonenumber: () => dispatch(changeForgotPassPhonenumber()),
        clearForgotRedux: () => dispatch(clearForgotRedux())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Forgotpass));