import React, { Component } from 'react';

class ErrorBest extends React.Component {
    render() {
        return (
            <>
                <div className="c-box__title">
                    <h2 className="c-box__title__name"><span className="is-small">TOP</span><br /><b>Dự án hay</b></h2>
                </div>
                <div>Hiện chưa có dữ liệu.</div>
            </>
        );
    }
}
export default ErrorBest;

