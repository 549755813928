import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';

class BestItemsLoading extends React.Component {
    render() {
        return (
            <>
                <div className="c-box__title">

                    <h2 className="c-box__title__name">
                        <span className="is-small">
                            <Skeleton animation="wave" width={54.72} height={34.44} />
                        </span>
                        <br />
                        <b>
                            <Skeleton animation="wave" width={252.99} height={65.56} />
                        </b>
                    </h2>
                </div>
                <div className="c-box__content">
                    <div className="c-project-top">
                        <div className="c-project-tabs">
                            <div className="c-project-tabs__title">
                                <ul>
                                    <li>
                                        <Skeleton animation="wave" height={80} />
                                    </li>
                                    <li >
                                        <Skeleton animation="wave" height={80} />
                                    </li>
                                    <li >
                                        <Skeleton animation="wave" height={80} />
                                    </li>
                                    <li >
                                        <Skeleton animation="wave" height={80} />
                                    </li>
                                    <li >
                                        <Skeleton animation="wave" height={80} />
                                    </li>
                                </ul>
                            </div>
                            <div className="c-project-tabs__container">
                                <div className="c-project-tabs__pane is-current active" >
                                    <div className="c-project-intro">
                                        <div className="c-project-intro__left">
                                            <Skeleton animation="wave" className="c-project-intro__thumb" style={{ backgroundColor: "#eee" }} />
                                        </div>
                                        <div className="c-project-intro__right">
                                            <div className="c-project-intro__row">
                                                <div className="c-project-intro__avatar">
                                                    <Skeleton animation="wave" variant="circle" width={44} height={44} />
                                                </div>
                                            </div>
                                            <div className="c-project-intro__row">
                                                <h2 className="c-project-intro__title">
                                                    <Skeleton animation="wave" height={20} />
                                                </h2>
                                                <span className="c-project-intro__author">
                                                    <Skeleton animation="wave" height={38} />
                                                </span>
                                            </div>
                                            <div className="c-project-intro__row">
                                                <div className="b-maincontent">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(BestItemsLoading);

