import React, { Component } from 'react'
import RequestButton from '../../../components/codekitten/request-btn/request-btn.jsx';
import styles from "./formStyle.css";

class ForgotpassLastStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            showPWAlert: false,
            activePW: false,
            validState: styles.focusedInput,
            sendRequest: false,
        }
        this.toggleInputType = this.toggleInputType.bind(this);
        this.submit = this.submit.bind(this);
    }
    
    toggleInputType() {
        this.setState({
            activePW: !this.state.activePW
        })
    }

    validation(pw) {
        return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(pw);
    }

    onChangePW(e) {
        let password = e.target.value;
        password = password.replace(/ /g,'');
        e.target.value = password;
        let isValid = this.validation(password);
        if(isValid) {
            this.setState({
                showPWAlert: false,
                validState: styles.isValidField,
                password:password
            })
        } else {
            this.setState({
                showPWAlert: true,
                validState: styles.isInvalidField,
                password:password
            })

        }
    }

    submit() {
        let password = this.state.password;
        if(this.validation(password)) {
            this.setState({
                sendRequest: true
            })
            this.props.submitLastStep(password);
        }
        return;
    }

    render() {
        return (
            <div className="c-auth-form">
                <div className="form-group">
                <div className="c-auth-box__otp">
                    <h6>Tạo lại mật khẩu</h6><i className="icon32-key" />
                    <p>
                    Tạo&nbsp;
                    <span className="h-text__underline">lại mật khẩu</span>
                    &nbsp;để thuận tiện cho bạn<br />khi đăng nhập
                    </p>
                </div>
                </div>
                <div className="form-group form-group-icons">
                <input className={"form-control " + this.state.validState}  type={this.state.activePW? "text":"password"} placeholder="Mật khẩu mới"  autoFocus onChange={event => this.onChangePW(event)}/><span className={`form-group-icon js-show-password ${this.state.activePW? "active" : ""}`} onClick={this.toggleInputType}><i className="icon16-eye-black" /></span>
                </div>
                {this.state.showPWAlert ? <span className="text-danger" >Mật khẩu phải có từ 8 ký tự trở lên bao gồm chữ HOA, chữ thường, chữ số và có thể có ký tự đặc biệt.</span> : null}
                <div className="form-group is-button">
                    <RequestButton content="Hoàn thành" onClick={this.submit} waitingForResponse={this.state.sendRequest}/>
                </div>
            </div>
        )
    }
}
export default ForgotpassLastStep;