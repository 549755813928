import React, { Component } from 'react';
import DefaultAvatar from '../../../components/codekitten/default-avatar/default-avatar.jsx';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import CollectionsIcon from '@material-ui/icons/Collections';
import styles from "../profile-page.css";
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import {updateUserAvatar} from "../../../reducers/codekitten/profile-reducer/userprofile-actions";
class UpdateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAvatar: "",
            fileUrl: "",
            selectedFile: null
        }
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.saveAvatar = this.saveAvatar.bind(this);
    }
    
    handleChangeFile(e) {
        if(typeof e.target.files[0] !== "undefined")
        this.setState({
            selectedFile: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0])
        })
        else 
        this.setState({
            selectedFile: null,
            fileUrl: this.state.currentAvatar
        })
    }
    saveAvatar() {
        if(this.state.selectedFile !== null) {
            const formData = new FormData();
            formData.append("avatar",
            this.state.selectedFile, 
            this.state.selectedFile.name);
            this.props.updateUserAvatar(formData);
        }
    }
    
    render() {
        return (
            <Modal
                show={true}
                onHide={this.props.backToHomepage}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                <Modal.Title>Chọn ảnh tải lên để cập nhật</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <figure className={styles.update_avatar_preview}><DefaultAvatar fontSize={150} avatar={this.state.selectedFile == null ? this.props.avatar : this.state.fileUrl} fullname={this.props.projectsAuthorName}/></figure>
                        <div className={"c-upload-image__input "+styles.input_avatar}>
                        <label className={" btn btn-light float-right " +styles.input_avatar_label} htmlFor={styles.upload_photo}><CollectionsIcon/> chọn ảnh</label>
                        <input type="file" name="photo" id={styles.upload_photo} onChange={this.handleChangeFile}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {this.props.error === "update avatar failed!" ? <div className="text-danger mt-2 text-center">Đã có lỗi xảy ra, vui lòng thử lại sau</div> : null}
                {this.props.error === "update avatar invalid!" ? <div className="text-danger mt-2 text-center">File không hợp lệ, vui lòng chọn file ảnh khác!</div> : null}
                {this.props.waitForUpdateAvatar ? <Loader className="TailSpin" type="Rings" color="#ffa31a" height={100} width={100}/> :
                <><Button variant="secondary" onClick={this.props.backToHomepage}>Hủy</Button>
                <Button variant="primary" onClick={this.saveAvatar}>Cập nhật</Button></>}
                </Modal.Footer>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => ({
    error: state.profile.updateAvatarError,
    waitForUpdateAvatar: state.profile.waitForUpdateAvatar
}
)
const mapDispatchToProps = (dispatch) => {
    return {
        updateUserAvatar: (formData) => dispatch(updateUserAvatar(formData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateComponent);