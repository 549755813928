import { HOME_PROJECTS_REQUEST, HOME_PROJECTS_SUCCESS, HOME_PROJECTS_ERROR,
  HOME_NEWS_REQUEST ,HOME_GET_NEWS_SUCCESS, HOME_GET_NEWS_ERROR } from '../constants/constants';
import projectsApi from '../../../api/projects-api';
import homeApi from '../../../api/home-api';
export const getProjectsFeatured = () =>  (dispatch)  => {
   
    dispatch(getProjectsRequest());
    projectsApi.getProjects({
        //tổng item trong 1 trang
        per_page: 15,
        //0 lấy mặc định
        featured: process.env.FEATURED_QUERY
    }).then(res => {
        dispatch(getProjectsSuccess(res.data.projects));
    }).catch(err => {
        dispatch(getProjectsFailed("Has Error!"));
    });
   }
   
export const getNewsFeatured = () => (dispatch) => {
    dispatch(getnEWSRequest());
    homeApi.getNews(6).then(response => {
      dispatch(getNewsSuccess(response))
    }).catch(error => {
      dispatch(getNewsFailed("Has Error!"))
    })
}

  const getProjectsRequest = () => ({
    type: HOME_PROJECTS_REQUEST
  });
  
  const getProjectsSuccess = (projects) => ({
    type: HOME_PROJECTS_SUCCESS,
    data: 
        {projects}
    ,
  });
  
 const getProjectsFailed = (error) => ({
    type: HOME_PROJECTS_ERROR,
    data:  {error} 
  });

 const getnEWSRequest = () => ({
    type: HOME_NEWS_REQUEST
  });

 const getNewsSuccess = (news) => ({
    type: HOME_GET_NEWS_SUCCESS,
    data: {
      news: news
    }
  });
  
 const getNewsFailed = (error) => ({
    type: HOME_GET_NEWS_ERROR,
    data:  {error} 
  });
