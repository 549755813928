import React, { Component } from 'react';

class Star extends Component {
    render() {
        return (
            <div>
                 <div className="b-grid">
                    <div className="b-grid__img">
                        <figure><img src={this.props.link_img} alt="star" /></figure>
                    </div>
                    <div className="b-grid__content">
                        <div className="b-grid__row">
                            <h3 className="b-grid__title">{this.props.name}</h3><span className="b-grid__pos">{this.props.age} tuổi, {this.props.school}</span>
                        </div>
                        <div className="b-grid__row b-grid__desc">{this.props.short_description}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Star;