import React from 'react';
import PropTypes from 'prop-types';
const HomeTopic = ({
    name,
    ...props
}) => {
    return (
        <div className="c-pano">
            <div className="c-pano__caption">
                <div className="container">
                    <h1 className="c-pano__title">{name}</h1>
                    
                </div>
                {/*end container*/}
            </div>
            {/*end c-pano__caption*/}
        </div>


    );
};
HomeTopic.propTypes = {
    name: PropTypes.string
};

export default HomeTopic;