exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".formStyle_focusedInput_3WjXQ:focus {\n    border-color: rgba(82,168,236,.8);\n    outline: 0;\n    outline: thin dotted \\9;\n    -webkit-box-shadow: 0 0 8px rgba(82,168,236,.6) !important;\n            box-shadow: 0 0 8px rgba(82,168,236,.6) !important;\n}\n\n.formStyle_isValidField_ToNkz {\n    border-color: rgba(0, 255, 0, 0.8);\n    outline: 0;\n    outline: thin dotted \\9;\n    -webkit-box-shadow: 0 0 8px rgba(0, 255, 0, 0.6) !important;\n            box-shadow: 0 0 8px rgba(0, 255, 0, 0.6) !important;\n}\n\n.formStyle_isInvalidField_2IUQo {\n    border-color: rgba(255, 5, 5, 0.911);\n    outline: 0;\n    outline: thin dotted \\9;\n    -webkit-box-shadow: 0 0 8px rgba(255, 37, 37, 0.7) !important;\n            box-shadow: 0 0 8px rgba(255, 37, 37, 0.7) !important;\n}", ""]);

// exports
exports.locals = {
	"focusedInput": "formStyle_focusedInput_3WjXQ",
	"isValidField": "formStyle_isValidField_ToNkz",
	"isInvalidField": "formStyle_isInvalidField_2IUQo"
};