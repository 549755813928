import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import ComponentItemsLoading from '../../project-featured/component-featured/item-loading.jsx';

class ComponentListLoading extends Component {
    renderLoadingItem(number) {
        return Array.from({ length: number }, () => <ComponentItemsLoading></ComponentItemsLoading>);
    }
    render() {
        return (
            <>
                <div className="l-content">
                    <div className="container">
                        <div className="c-box">

                            <div className="c-box__content">
                                <div className="c-project-grid">
                                    <ul>
                                        {this.renderLoadingItem(16)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default ComponentListLoading;





