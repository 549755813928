import React, { Component } from 'react'

export default class HomeAbout extends Component {
    render() {
        return (
            <div className="container">
            <div className="c-block bg-danger is-inner-padding is-border-radius c-about-block">
                <div className="c-about">
                <div className="c-about__content">
                    <div className="c-box">
                    <div className="c-box__title">
                        <div className="c-box__title__name"><span className="is-small">Về Codekitten</span><br /><b>Trên cả lập trình</b></div>
                    </div>
                    <div className="c-box__content">
                        <div className="b-maincontent">
                        <ul>
                            <li>Nền tảng lập trình kéo thả thuần Việt phát triển bởi Teky, trên nền công nghệ Scratch, MIT</li>
                            <li>Bộ thư viện hình nền, nhân vật, đối tượng lập trình được thiết kế gắn liền với lịch sử, anh hùng dân tộc, di sản nổi tiếng, danh lam thắng cảnh, bản sắc văn hoá</li>
                            <li>Nhiều bài học, thử thách mô phỏng, liên kết với kiến thức K12, từ Toán học, Vật Lý, Hoá học, Sinh học, Khoa học vũ trụ, Lịch sử v.v.. giúp ghi nhớ kiến thức trên lớp tốt hơn.</li>
                            <li>Mỗi bài học là một dự án làm Game đầy thú vị, mang đậm màu sắc sáng tạo cá nhân của mỗi học sinh.</li>
                            <li>Chia sẻ dự án cá nhân với toàn bộ cộng đồng học sinh lập trình toàn quốc, cùng chơi game của nhau, chia sẻ ý tưởng, kiến tạo những ước mơ bằng những dòng lệnh.</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="c-about__img"><img src="/static/upload/about.png" alt="about" /></div>
                </div>
            </div>
            {/*end c-block*/}
            </div>
        )
    }
}
