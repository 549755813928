import React, { Component } from 'react';
import PageComponent from '../../components/codekitten/tutorials-pagination/page-component.jsx';
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx';
import { getTutorialsInpage } from '../../reducers/codekitten/tutorials-reducer/tutorials-actions.js';
import { connect } from 'react-redux';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx'
import { setRegisterSource } from '../../reducers/codekitten/register-reducer/register-source-actions.js';

class Tutorials extends Component {
    constructor(props) {
        super(props);
        this.getArticlesInPage = this.getArticlesInPage.bind(this);
    }

    getArticlesInPage(page_num) {
        window.scrollTo(0,0);
        this.props.getTutorialsInpage(page_num);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.props.tutorials == null || this.props.tutorials.length == 0)
        this.getArticlesInPage(1);

        const QueryParams = new URLSearchParams(this.props.location.search); 
        const utm_source = QueryParams.get('utm_source');
        if(typeof utm_source !== "undefined" && utm_source !== "" && utm_source !== null) {
            this.props.setRegisterSource(utm_source);
        }
    }

    render() {
        const {onPage, total_page, tutorials, loading} = this.props;
        return (
            <>
            <SeoComponent title="Kiến thức"/>
                <HomeTopic name="Kiến thức"></HomeTopic>
                {/*end c-pano*/}

                <div className="l-content">
                    <div className="container">
                    <PageComponent tutorialList={tutorials} total_page={total_page}
                                    onPage={onPage} moveToPage={this.getArticlesInPage}
                                    loading={loading}></PageComponent>
                    </div>
                    {/*end container*/}
                </div>
                
            </>
        );
    }
};

const mapStateToProps = (state) => ({
    onPage: state.tuto.currentPage,
    total_page: state.tuto.totalPage,
    tutorials: state.tuto.tutorials,
    loading: state.tuto.loadingTutorials
})

const mapDispatchToProps = (dispatch) => {
    return {
        getTutorialsInpage: (page_num) => dispatch(getTutorialsInpage(page_num)),
        setRegisterSource: (utm_source) => dispatch(setRegisterSource(utm_source))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tutorials);
