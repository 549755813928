// import parse from 'html-react-parser';

import React, { Component } from 'react';
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx'
import projectsApi from '../../api/projects-api';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Markup } from 'interweave';
import { Link } from 'react-router-dom';
import styles from "../project-featured/project-featured.css";
import DefaultAvatar from '../../components/codekitten/default-avatar/default-avatar.jsx';
import { compose } from 'redux';
import AppStateHOC from '../../lib/app-state-hoc.jsx';
import GUI from '../../containers/gui.jsx';
import HashParserHOC from '../../lib/hash-parser-hoc.jsx';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import guiApi from '../../api/gui-api';
import JoinContestDialog from '../../components/join-contest-dialog/join-contest-dialog.jsx'
import eyeIcon from './eye.svg'
import miniTasksStyles from './project-detail.css'
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx'
import NotFound from '../not-found/not-found.jsx'

const WrappedGui = compose(
  AppStateHOC,
  HashParserHOC
)(GUI);
class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      creator: {},
      loading: true,
      statusUpdateDes: '',
      isFullScreen: false,
      openJoinContestForm: false,
      view: 0,
      notfound:false
    }
  }
  componentWillMount() {
  
  }
  componentDidMount() {

    window.fbAsyncInit = () => {
      window.FB.init({
          appId            : process.env.FACEBOOK_APP_ID,
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v12.0'
      });
  };
  (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/vi_VN/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
  if (window.FB) {window.FB.XFBML.parse();}
    ClassicEditor
      .create(document.querySelector('#editor'), {
        toolbar: ['bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList']
      })
      .catch(error => {
        console.log(error);
      });
    if (typeof this.props.location.state != 'undefined' && this.props.location.state.fullScreen) {
      this.setState({
        isFullScreen: true
      });
    }
    window.scrollTo(0, 0)
    let projectId = this.props.match.params.projectId;
    projectsApi.getProjectDetail(projectId).then((response) => {
      if(response.message.status_code===200){
        this.setState({
          loading: false,
          project: response.data,
          creator: response.data.creator,
          view: response.data.stats.views,
          notfound:false
        });
      }else{
        this.setState({
          notfound:true
        });
      }
      
    });
  }
  upDateDescriptionProject(data) {
    this.setState({
      statusUpdateDes: 'Đang cập nhật...'
    });
    guiApi.upDateDescriptionProject(this.props.match.params.projectId, data).then(response => {
      this.setState({
        statusUpdateDes: 'Cập nhật thành công !'
      });

      setTimeout(function () {
        this.setState({
          statusUpdateDes: ''
        });
      }.bind(this), 2000);


    }).catch(error => {
      this.setState({
        statusUpdateDes: 'Cập nhật thất bại !'
      });

      setTimeout(function () {
        this.setState({
          statusUpdateDes: ''
        });
      }.bind(this), 2000);
    })
  }

  render() {
    var { project, creator } = this.state;
    if(this.state.notfound) return <NotFound/>
    return (
      <>
      <div id="fb-root"></div>
      {!this.state.loading?<SeoComponent title={`${project.name}`} description={`${project.description}`} image={`${project.thumbnail === "" || project.thumbnail == null ? "/static/images/thumbnails/default-thumbnail.png" : project.thumbnail}`} />:null}
        <HomeTopic name="Chi tiết dự án"></HomeTopic>
        {/*end c-pano*/}
        <div className="l-content" >
          <div className="container">
            <div className="c-project-detail">
              <div className="c-project-view">
                <div className={`c-project-play ${this.state.isFullScreen ? 'is-full' : ''}`}>
                  <div className="c-project-play__thumb" >
                    <WrappedGui

                      assetHost={`${process.env.BASE_URL}/api/v1/codekitten/assets`}
                      canSave={this.state.canSave}
                      projectHost={`${process.env.BASE_URL}/api/v1/codekitten/projects`}
                      projectId={`${this.props.match.params.projectId}`}
                      canEditTitle
                      isStarted={false}
                      isFullScreen={typeof this.props.location.state != 'undefined' && this.props.location.state.fullScreen ? true : false}
                      isPlayerOnly
                      onFullScreen={(isFullScreen) => {
                        this.setState({
                          isFullScreen: isFullScreen
                        });
                      }}

                    />
                    {/* <Link style={{ backgroundImage: `url(${project.thumbnail === "" || project.thumbnail == null ? "/static/images/thumbnails/default-thumbnail.png" : project.thumbnail})` }} /> */}

                  </div>
                  <div className="c-project-play__iframe">
                    <iframe />
                  </div>
                </div>
                <div className="c-project-view__bar">
                  <div className="c-project-view__head">
                    <div className="c-project-view__avatar">
                      {typeof project.creator !== "undefined" ?
                        <DefaultAvatar fullname={creator.full_name} avatar={creator.avatar} /> :
                        null}
                    </div>
                    <div className="c-project-view__text">
                      <h1 className="c-project-view__title" className={styles.hoverText}>{project.name}</h1>
                      <h2 className="c-project-view__author">
                        <span>
                          Bởi:&nbsp;
                          <b className={styles.cProjectItemAuthorB} onClick={() => this.props.history.push(`/users/${project.creator.uniq_random_code}/projects`)}>
                            {typeof project.creator !== "undefined" ? creator.full_name : null}</b>
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div className="c-project-view__btn"><a href={`/projects/${this.props.match.params.projectId}/editor`} className="btn btn-primary btn-lg is-circle btn-block">Xem code</a></div>
                </div>
              </div>
              <div className={miniTasksStyles.projectSmallTasks}>
                <div className={miniTasksStyles.projectSmallTasksLeft}>
                  <img style={{ width: "28px" }} src={eyeIcon} />
                  <h5 >{this.state.view} lượt xem</h5>
                </div>
                {this.state.creator.id === this.props.userId ?
                  <div>
                    <Link className={miniTasksStyles.joinContestBtn} onClick={() => this.setState({ openJoinContestForm: true })}>Tham gia cuộc thi</Link>
                    <JoinContestDialog open={this.state.openJoinContestForm} handleClose={() =>
                      this.setState({ openJoinContestForm: false })}
                      projectId={`${this.props.match.params.projectId}`} />
                  </div> : null}
              </div>
              <div className="c-project-border">
                <div className="c-project-label">Giới thiệu sản phẩm</div>
                <div>{this.state.statusUpdateDes}</div>
                {!this.state.loading ?
                  (
                    this.props.token !== '' && this.props.userId === this.state.creator.id ? <CKEditor
                      editor={ClassicEditor}
                      data={`${project.description}`}
                      disabled={false}
                      config={{ toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'] }}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        this.upDateDescriptionProject(editor.getData())
                        console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                      }}
                    /> : <div className="b-maincontent">
                      <Markup content={project.description} />
                    </div>
                  ) : <div>Loading</div>
                }


              </div>

              <div style={{ textAlign: 'center' }}>

              <div style={{ display:'flex',justifyContent:'start',alignItems:'start'}}><div class="fb-like" data-href={`${window.location.href}`} data-width="200px" data-layout="button_count" data-action="like" data-size="large" data-share="true"></div></div>
              <div class="fb-comments" data-href={`${window.location.href}`} data-width="100%" data-numposts="5"></div>
              
              </div>

              {/* <div className="c-project-label">Kiến thức liên quan</div>
              <div className="c-project-tags"><a href="#">Lập trình</a><a href="#">Scratch</a><a href="#">4-8 tuổi</a><a href="#">Bé làm Game</a></div> */}
            </div>
          </div>
          {/*end container*/}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    token: state.auth.token,
    userId: state.auth.id,
    userUniqCode: state.auth.uniq_random_code
  }
}

export default connect(mapStateToProps, null)(withRouter(ProjectDetail));
