import React, { Component } from 'react';
import { connect } from 'react-redux';
import PositionMapPriates from '../../../lib/libraries/challenge/position-map-priates.json';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getProjectJsonFile } from '../../../reducers/codekitten/data-project-reducer/data-project-action';
import PositionMapTreasures from '../../../lib/libraries/challenge/position-map-treasure.json';
import LocationMapIcon from './location-map.svg';
 
class ChallengingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            challenge_name: "",
            bg_challenge_name: "",
            challenges: [],
            backgroundImg: "/static/upload/challenging/pirates/map-pirates.png", // cái này sẽ để set state theo challenge

        }
        this.onClickItem = this.onClickItem.bind(this);
    }

    componentDidMount() {
        this.setState({
            challenge_name: this.props.challenge_name,
            bg_challenge_name: this.props.challenge_name.substring(12, this.props.challenge_name.length),
            challenges: this.props.Challenges,
            backgroundImg: this.props.map === 'pirates' ? "/static/upload/challenging/pirates/map-pirates.png" : '/static/upload/challenging/treasure/map-treasure.png'
        })

    }
    onClickItem(idTutorial, idProject) {

        if (idProject !== '') {
            this.props.getProjectJsonFile(idProject).then(newState => {
                if (newState.dataProjectJson.error === '') {
                    this.props.history.push(`/projects/editor?tutorial=${idTutorial}`, { tutorial: `?tutorial=${idTutorial}`, projectId: `${idProject}`, projectJson: newState.dataProjectJson.projectJson })
                }
            });
        } else {
            this.props.history.push(`/projects/editor?tutorial=${idTutorial}`, { tutorial: `?tutorial=${idTutorial}` })

        }
    }

    render() {
        return (
            <div>
                {/*Content page part*/}
                <div className="c-challenging-bg">
                    <div className={`c-challenging-box ${this.props.map === 'pirates' ? 'bg-pirates' : 'bg-treasure'}`}>
                        <div className="c-challenging-box__off">Vui lòng xem trên máy tính</div>
                        <div className="c-challenging-box__title">
                            <div className="container"><img src="/static/images/challenging-title.png" alt="Thử thách" /></div>
                        </div>
                        <div className="c-challenging-box__content">

                            <div className={`c-challenging-map c-challenging-${this.props.map === 'pirates' ? 'pirates' : 'treasure'}`}><img src={this.state.backgroundImg} alt="background" />
                                <div className={`c-kitten active-${this.state.activeChallengeBox}`}><img src="/static/images/kitten.png" alt="kitten" /></div>
                                {this.state.challenges.map((challenge_box, index) => {
                                    //max chỉ được 12 item  
                                    return index > 12 ? (<div />) : (
                                        <div key={index} className={`c-place is-pos-${challenge_box.index}`}
                                            style={this.props.map === 'pirates' ?
                                                { top: PositionMapPriates[index].top, left: PositionMapPriates[index].left }
                                                : { top: PositionMapTreasures[index].top, left: PositionMapTreasures[index].left }}>
                                            <a className="c-place__link" style={{ cursor: "pointer" }} onClick={() => {
                                                this.onClickItem(challenge_box.tutorial_id, challenge_box.project_id);

                                            }} />
                                            <div className="c-place__cloud"><span className="is-medium">Thử thách {challenge_box.index}:</span><br />{challenge_box.name}</div>
                                            <div className="c-place__img"><img src={LocationMapIcon} alt={"Vị trí " + challenge_box.index} /><img style={{ width: '58px', height: '58px', borderRadius: '50%', position: "absolute", left: '23px', top: '16px' }} src={challenge_box.img} /></div>
                                            <div className="c-place__other">
                                                <div className="c-place__number">{challenge_box.index}</div>
                                                <div className="c-place__do">Làm ngay</div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                {/* c-challenging-bg*/}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({

    getProjectJsonFile: (idProject) => dispatch(getProjectJsonFile(idProject))
})
export default connect(null, mapDispatchToProps)(withRouter(ChallengingComponent));