import React, { Component } from 'react';
import ItemProjectBestComponent from './item-project-best.jsx';
import { Markup } from 'interweave';
import { withRouter } from 'react-router-dom';
import styles from "../project-featured.css";
import { Link } from 'react-router-dom';
import DefaultAvatar from '../../../components/codekitten/default-avatar/default-avatar.jsx';
class ProjectBest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: this.props.projects,
            indexActive: 0,
            projectActive: this.props.projects.length > 0 ? this.props.projects[0].project : []
        }

    }



    render() {
        return (
            <>
                <div className="c-box__title">
                    <h2 className="c-box__title__name"><span className="is-small">TOP</span><br /><b>Dự án hay</b></h2>
                </div>
                <div className="c-box__content">
                    <div className="c-project-top">
                        <div className="c-project-tabs">
                            <div className="c-project-tabs__title">
                                <ul>
                                    {this.state.projects.map((value, index) => (
                                        <ItemProjectBestComponent
                                            key={index}
                                            href={`#tab${index + 1}`}
                                            srcImg={value.project.thumbnail}
                                            isActive={index === this.state.indexActive ? true : false}
                                            onClick={() => {
                                                this.setState({
                                                    indexActive: index,
                                                    projectActive: value.project,
                                                }, () => {
                                                    console.log(this.state.projectActive);
                                                });
                                            }}
                                        />
                                    ))}
                                </ul>
                            </div>
                            <div className="c-project-tabs__container">
                                <div className="c-project-tabs__pane is-current active" >
                                    <div className="c-project-intro">
                                        <div className="c-project-intro__left">
                                            <div className="c-project-intro__thumb">

                                                <Link to={`/projects/${this.state.projectActive.id}`}
                                                    style={{ backgroundImage: this.state.projectActive.thumbnail === "" || this.state.projectActive.thumbnail == null ? "url(/static/images/thumbnails/default-thumbnail.png)" : `url(${this.state.projectActive.thumbnail})` }}>
                                                </Link>
                                                <div className="c-project-intro__btn">

                                                 {/* <a><div onClick={()=>{ history.pushState({},'',`/projects/${this.state.projectActive.id}/editor`)} }> Xem code</div> */}
                                                     <a href={`/projects/${this.state.projectActive.id}/editor`}   >Xem code</a>
                                                    <Link to={{
                                                        pathname: `/projects/${this.state.projectActive.id}`,
                                                        state: { fullScreen: true }
                                                    }}  >Chơi ngay</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="c-project-intro__right">
                                            <div className="c-project-intro__row">
                                                <div className="c-project-intro__avatar">
                                                    <Link style={{ textDecoration: 'none' }} to={`/users/${this.props.projects[this.state.indexActive].project.user_code}/projects`}>
                                                        <DefaultAvatar avatar={this.state.projects[this.state.indexActive].author.avatar}
                                                            fullname={this.state.projects[this.state.indexActive].author.full_name} />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="c-project-intro__row">
                                                <h2 className="c-project-intro__title ">
                                                    <span className={styles.hoverText} onClick={() => { this.props.history.push(`/projects/${this.state.projectActive.id}`); }} >
                                                        {this.state.projectActive.name}
                                                    </span>
                                                </h2>

                                                <span className="c-project-intro__author">
                                                    Bởi: <b className={styles.cProjectItemAuthorB}
                                                        onClick={() => this.props.history.push(`/users/${this.props.projects[this.state.indexActive].project.user_code}/projects`)}>
                                                        {this.state.projects[this.state.indexActive].author.full_name}</b></span>
                                            </div>
                                            <div className="c-project-intro__row">
                                                <div className="b-maincontent">
                                                    <Markup content={this.state.projectActive.description} />
                                                    {/* {parse(this.state.projectActive.description)} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withRouter(ProjectBest);

