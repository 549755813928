exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".profile-page_small_edit_JGeB7 {\n    font-size: 15px!important;\n}\n\n.profile-page_update_avatar_preview_2JHZd {\n    width: 380px!important;\n    height: 380px!important;\n    margin: auto!important;\n    padding: auto!important;\n}\n\n.profile-page_input_avatar_2Qnck {\n    padding-left: auto;\n    padding-right: auto;\n    margin: auto;\n    width: 100%;\n    -webkit-align-content: center;\n        -ms-flex-line-pack: center;\n            align-content: center;\n    /* Style as you please, it will become the visible UI component. */\n }\n\n.profile-page_input_avatar_label_STx07{\n    padding: auto;\n    margin: auto;\n    cursor: pointer;\n }\n\n#profile-page_upload_photo_Kwtso {\n    opacity: 0;\n    position: absolute;\n    z-index: -1;\n    width: 0;\n    height: 0;\n }\n\n.profile-page_update_info_inputfield_33cJx {\n    width: 100%!important;\n    min-height: 30px!important;\n    font-family: \"Quicksand\", sans-serif!important;\n    border-radius: 6px!important;\n}\n\n.profile-page_update_info_label_Wjeq8 {\n    font-family: \"Quicksand\", sans-serif;\n    color: black!important;\n    font-weight: bolder!important;\n}\n\n.profile-page_hoverText_2W_gu {\n    cursor: pointer;\n    position: relative;\n    display: inline-block;\n    color: rgb(61, 139, 190);\n    overflow: hidden;\n    background: -webkit-gradient(linear, left top, right top, from(#5BC0A6) , color-stop(50%, #5BC0A6), color-stop(50%, #3944d8));\n    background: -o-linear-gradient(left, #5BC0A6 , #5BC0A6 50%, #3944d8 50%);\n    background: linear-gradient(to right, #5BC0A6 , #5BC0A6 50%, #3944d8 50%);\n    background-clip: text;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-size: 200% 100%;\n    background-position: 100%;\n    text-decoration: none;\n}\n\n.profile-page_hoverText_2W_gu:hover {\n    background-position: 0 100%;\n}\n", ""]);

// exports
exports.locals = {
	"small_edit": "profile-page_small_edit_JGeB7",
	"smallEdit": "profile-page_small_edit_JGeB7",
	"update_avatar_preview": "profile-page_update_avatar_preview_2JHZd",
	"updateAvatarPreview": "profile-page_update_avatar_preview_2JHZd",
	"input_avatar": "profile-page_input_avatar_2Qnck",
	"inputAvatar": "profile-page_input_avatar_2Qnck",
	"input_avatar_label": "profile-page_input_avatar_label_STx07",
	"inputAvatarLabel": "profile-page_input_avatar_label_STx07",
	"upload_photo": "profile-page_upload_photo_Kwtso",
	"uploadPhoto": "profile-page_upload_photo_Kwtso",
	"update_info_inputfield": "profile-page_update_info_inputfield_33cJx",
	"updateInfoInputfield": "profile-page_update_info_inputfield_33cJx",
	"update_info_label": "profile-page_update_info_label_Wjeq8",
	"updateInfoLabel": "profile-page_update_info_label_Wjeq8",
	"hoverText": "profile-page_hoverText_2W_gu"
};