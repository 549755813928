exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".project-featured_pointerPlay_3xI5U{\n    cursor: pointer;\n}\n\n.project-featured_cProjectItemAuthorB_2XETJ {\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n.project-featured_hoverText_26nk_ {\n    cursor: pointer;\n    position: relative;\n    display: inline-block;\n    color: black;\n    overflow: hidden;\n    background: -webkit-gradient(linear, left top, right top, from(#5BC0A6) , color-stop(50%, #5BC0A6), color-stop(50%, black));\n    background: -o-linear-gradient(left, #5BC0A6 , #5BC0A6 50%, black 50%);\n    background: linear-gradient(to right, #5BC0A6 , #5BC0A6 50%, black 50%);\n    background-clip: text;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-size: 200% 100%;\n    background-position: 100%;\n    text-decoration: none;\n}\n\n.project-featured_hoverText_26nk_:hover {\n    background-position: 0 100%;\n}\n\n#project-featured_ItemProjectBestComponentImg_2S_7R {\n    border-radius: 10px!important;\n}", ""]);

// exports
exports.locals = {
	"pointerPlay": "project-featured_pointerPlay_3xI5U",
	"cProjectItemAuthorB": "project-featured_cProjectItemAuthorB_2XETJ",
	"hoverText": "project-featured_hoverText_26nk_",
	"ItemProjectBestComponentImg": "project-featured_ItemProjectBestComponentImg_2S_7R",
	"itemProjectBestComponentImg": "project-featured_ItemProjectBestComponentImg_2S_7R"
};