import React, { Component } from 'react';
import HomeMenu from './home-menu.jsx'
import DefaultAvatar from "../../codekitten/default-avatar/default-avatar.jsx"
import '../../../../static/css/style.min.css'
import { withRouter, Link } from "react-router-dom";
import { removeUserProfile } from "../../../reducers/codekitten/auth-reducer/auth-actions";
import { connect } from 'react-redux';
import authApi from '../../../api/auth-api.js';
import LoadingBar from 'react-redux-loading-bar';

class HomeHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: this.props.loggedIn,
            avatar: this.props.avatar,

        };
        this.logout = this.logout.bind(this);
    }

    logout() {

        authApi.logout(this.props.token).then(response => {
            const status_code = response.message.status_code;
            if (status_code == 200) {
                this.props.removeUserProfile();
                this.props.history.push("/");
            }
        })
    }
  

    componentDidMount() {
    }

    render() {
        const { uniq_random_code, username, fullname, avatar, loggedIn } = this.props
        return (
            <div className="l-nav has-fixed">
                <div className="c-header">
                <LoadingBar style={{
                        backgroundColor: '#5BC0A6', height: '4px', position: "fixed",
                        top: "0px"
                    }} /> 
                    <div className="container">
                        <div className="c-header-inner">
                            <div className="c-logo">
                                <Link to="/">
                                    <img src="/static/images/logo.png" alt="logo" />
                                </Link>
                            </div>
                            <button className="c-menu-expand js-menu-expand" type="button"><span /></button>
                            <div className="c-header-right">
                                <div className="c-header-admin">
                                    <div className="c-admin-nologin">
                                        <ul >
                                            <li className="li-button">
                                                <Link to="/projects/editor">Bắt đầu ngay</Link>
                                            </li>
                                            {loggedIn ? (
                                                <li>
                                                    <div className="c-user-login js-dropdown-hover"><Link to={{ pathname: `/users/${uniq_random_code}/projects` }} style={{ textDecoration: 'none' }}>
                                                        <figure><DefaultAvatar avatar={avatar}
                                                            fullname={fullname}></DefaultAvatar></figure>
                                                        <span>{fullname}</span></Link>
                                                        <div className="c-dropdown-menu is-right">
                                                            <ul>
                                                                <li><Link to={{ pathname: `/users/${uniq_random_code}/projects` }}><i className="icon24-setting icon-left" />Tài khoản cá nhân</Link></li>
                                                                <li><Link onClick={this.logout}><i className="icon24-log-out-white icon-left" />Đăng xuất</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                                : (
                                                    <li>
                                                        <div className="c-user-guest">
                                                            <ul>
                                                                <li>
                                                                    <Link to="/register">Đăng ký</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/login">Đăng nhập</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <HomeMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        loggedIn: state.auth.loggedIn,
        uniq_random_code: state.auth.uniq_random_code,
        username: state.auth.username,
        fullname: state.auth.fullname,
        avatar: state.auth.avatar,
        authError: state.auth.authError,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeUserProfile: () => dispatch(removeUserProfile())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeHeader));
