import React from 'react';

const AboutUs = () => {
    return (
        <React.Fragment>
            <div className="c-footer-label">CodeKitten</div>
            <div className="c-footer-content">
                <div className="c-footer-list">
                    <ul>
                        <li><a href="https://teky.edu.vn/about-us/" target="_blank">Về chúng tôi</a></li>
                        <li><a href="https://teky.edu.vn/chinh-sach-bao-ve-thong-tin-ca-nhan-khach-hang/" target="_blank">Chính sách bảo vệ thông tin</a></li>
                        <li><a href="https://teky.edu.vn/chinh-sach-quy-dinh-chung/" target="_blank">Điểu khoản và Quy định</a></li>
                        <li><a href="https://teky.edu.vn/contact/" target="_blank">Liên hệ</a></li>
                        <li><a href="https://codekitten.vn/blog/" target="_blank">Blog</a></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutUs;