import React, { Component } from 'react';
import NoneProjectComponent from '../../../views/user-homepage/user-hompage-component/none-project-component.jsx';
import ComponentItemsLoading from '../../../views/project-featured/component-featured/item-loading.jsx';
import ComponentItems from '../../../views/project-featured/component-featured/component-items.jsx';
import PaginationNav from '../tutorials-pagination/pagination-nav.jsx';

class PageProjectComponent extends Component {
    render() {
        return (
            <>
                {this.props.total_item == 0 && !this.props.loading? <NoneProjectComponent></NoneProjectComponent> :
                (
                    <>
                    <div className="c-box__title">
                    <h2 className="c-box__title__name"><span className="is-small">
                        Dự án
                    </span>
                        <br />
                        <b>
                        </b>
                    </h2>
                </div>
                <div className="c-box__content">
                    <div className="c-project-grid">

                        {this.props.loading ? (
                            <div className="c-project-grid">
                                <ul>
                                    {Array.from({ length: 8 }, () => <ComponentItemsLoading></ComponentItemsLoading>)}
                                </ul>
                            </div>
                        ) : (
                            <ul>
                                {
                                    this.props.projects.map((item, key) => (
                                        <li key={key.toString()}>
                                            <ComponentItems
                                                isInMyProfile={this.props.isInMyProfile}
                                                fullname={this.props.projectsAuthorName}
                                                userCode={this.props.userCode}
                                                avatar={this.props.avatar}
                                                projectId={item.project.id}
                                                userId={this.props.userId}
                                                name={item.project.name}
                                                thumbnail={item.project.thumbnail}
                                                deleteMyProjectConfirm={() => this.props.deleteMyProjectConfirm(item.project.id, item.project.name)}>
                                            </ComponentItems>
                                        </li>
                                    ))
                                }
                            </ul>
                        )}

                    </div>

                    <PaginationNav moveToPage={this.props.moveToPage} total_page={this.props.total_page} activePage={this.props.current_page}></PaginationNav>
                    {/* b-page*/}
                </div>
                    </>
                )}
            </>
        );
    }
}

export default PageProjectComponent;
