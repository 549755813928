import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

class ComponentItemsLoading extends Component {

    render() {
        return (
            <li>
                <div className="c-project-item" >
                    <div>
                        <a>
                          
                        <Skeleton style={{height:"200px"}} animation="wave" className="c-project-item__thumb" />
                        </a>
                    </div>
                    <div className="c-project-item__content">
                        <div className="c-project-item__avatar">
                            <Skeleton animation="wave" circle={true} width={43.7} height={43.7} />
                        </div>
                        <div className="c-project-item__row">
                            <Skeleton animation="wave" className="c-project-item__title" />
                            <Skeleton animation="wave" className="c-project-item__author" />
                        </div>
                        <div className="c-project-item__row">
                            <Skeleton animation="wave" height={29.55} />
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default ComponentItemsLoading;