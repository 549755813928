import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import ComponentItemsLoading from './item-loading.jsx';


class ComponentBodyItemsLoading extends Component {
    
    renderLoadingItem(number) {
        return Array.from({ length: number }, () => <ComponentItemsLoading></ComponentItemsLoading>);
    }

    render() {
        return (
            <div className="c-box is-margin-medium">
                <div className="c-box__title is-button">
                    <h2 className="c-box__title__name">

                        <span className="is-small">
                            <Skeleton animation="wave" width={88.36} height={39} />
                        </span>
                        <br />
                        <b>
                            <Skeleton animation="wave" width={250} height={50} />
                        </b>
                    </h2>

                    <div className="c-box__title__btn">
                        <a className="btn btn-primary is-circle" onClick={this.gotoListProject}>
                            <b>Xem toàn bộ</b>
                        </a>
                    </div>
                </div>
                <div className="c-box__content">
                    <div className="c-project-grid">
                        <ul>
                            {this.renderLoadingItem(4)}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default ComponentBodyItemsLoading;