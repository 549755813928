exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dialog-styles_dialog_header_2-JZk {\n    font-family: \"Quicksand\", sans-serif !important;\n    font-weight: bold;\n    font-size: 25px;\n}\n.dialog-styles_dialog_font_family_bT7EE {\n    font-family: \"Quicksand\", sans-serif;\n    color: black;\n}", ""]);

// exports
exports.locals = {
	"dialog_header": "dialog-styles_dialog_header_2-JZk",
	"dialogHeader": "dialog-styles_dialog_header_2-JZk",
	"dialog_font_family": "dialog-styles_dialog_font_family_bT7EE",
	"dialogFontFamily": "dialog-styles_dialog_font_family_bT7EE"
};