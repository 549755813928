import React, { Component } from 'react';
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageProjectComponent from '../../components/codekitten/my-projects/page-project-component.jsx';
import UserProfileComponent from './user-hompage-component/user-profile-component.jsx';
import projectsApi from '../../api/projects-api.js';
import { getUserProfileByRandomCode } from "../../reducers/codekitten/profile-reducer/userprofile-actions";
import ConfirmDialog from '../authen/dialog/confirm-dialog.jsx';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx';
import  { Redirect } from 'react-router-dom'
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            total_page: 1,
            total_item: 0,
            current_page: 1,
            loadingProjects: true,
            showDeleteConfirm: false,
            deleteProjectName: "",
            deleteProjectId: "",
        }
        this.getProjectsInPage = this.getProjectsInPage.bind(this);
        this.deleteMyProject = this.deleteMyProject.bind(this);
    }

    componentDidMount() {
        this.props.getUserProfileByRandomCode(this.props.match.params.UniqRandomCode)
        this.getProjectsInPage(null);
    }

    componentDidUpdate() {
        window.scroll(0, 0);
        if (this.props.authorRandomCode !== "" && this.props.authorRandomCode !== this.props.match.params.UniqRandomCode) {
            this.props.getUserProfileByRandomCode(this.props.match.params.UniqRandomCode) // thực chất là sử dụng uniq random code
            this.getProjectsInPage(null);
            return;
        }
        if (this.props.error !== "") {
            this.props.history.push("/notfound");
        }
    }

    getProjectsInPage(page_num) {
        window.scrollTo(0, 0);
        // let userId = this.props.match.params.UniqRandomCode;
        let userRandomCode = this.props.match.params.UniqRandomCode;
        this.setState({
            loadingProjects: true
        })
        console.log('sonnnn',userRandomCode)
        projectsApi.getMyProjectsByUniqRandomCode(userRandomCode, page_num, process.env.PROFILE_PROJECTS_PER_PAGE).then((value) => {
            const status = value.message.status;
            if (status === "Success") {
                this.setState({
                    projects: value.data.projects,
                    total_page: value.data.total_page,
                    total_item: value.data.total_item,
                    current_page: value.data.current_page,
                    loadingProjects: false
                })
            } else if (status === "Fail") {
                this.props.history.push("/notfound");
            }

        }, (error) => {
            console.log('sonnnn',error)
            console.log(error);
            this.props.history.push("/notfound");
        });
    }

    handleCloseDeleteAlert = () => {
        this.setState({
            showDeleteConfirm: false,
            deleteProjectName: "",
            deleteProjectId: ""
        })
    };

    deleteMyProjectConfirm = (project_id, projectname) => {
        this.setState({
            showDeleteConfirm: true,
            deleteProjectName: projectname,
            deleteProjectId: project_id
        })
    }

    deleteMyProject() {
        this.handleCloseDeleteAlert();
        projectsApi.deleteMyProject(this.state.deleteProjectId, this.props.token).then(response => {
            if (response.message.status_code == 200) {
                this.getProjectsInPage(this.state.current_page);
            } else {
                alert("Lỗi xóa dự án!");
            }
        }).catch(error => {
            alert("Lỗi xóa dự án!");
        })
    }

    render() {
        const { avatar, projectsAuthorName, authorId, authorRandomCode, currentUserRandomCode, loading } = this.props;
        return (
            <>
                {loading?null:<SeoComponent title={projectsAuthorName}/>}
                <HomeTopic name="Cá nhân"></HomeTopic>
                <div className="l-content">
                    <div className="container">
                        {/* PHẦN PROFILE */}
                        <UserProfileComponent loading={loading} avatar={avatar}
                            projectsAuthorName={projectsAuthorName}
                            isAuthor={authorRandomCode == currentUserRandomCode}
                        />

                        {/* PHẦN Dự ÁN */}
                        <div className="c-box">
                            <PageProjectComponent
                                isInMyProfile={authorRandomCode === currentUserRandomCode}
                                projects={this.state.projects} userId={authorId}
                                userCode={authorRandomCode}
                                projectsAuthorName={projectsAuthorName}
                                avatar={avatar}
                                loading={this.state.loadingProjects}
                                current_page={this.state.current_page}
                                total_page={this.state.total_page}
                                total_item={this.state.total_item}
                                moveToPage={this.getProjectsInPage}
                                deleteMyProjectConfirm={this.deleteMyProjectConfirm}></PageProjectComponent>
                        </div>
                    </div>
                </div>

                {/* Alert Dialog for delete project */}
                <ConfirmDialog
                    isDangerousAction={true} open={this.state.showDeleteConfirm}
                    title="Xóa dự án?" description={`Bạn có chắc muốn xóa dự án <b class="text-danger">${this.state.deleteProjectName}</b>`}
                    cancelContent="Không xóa" cancel={this.handleCloseDeleteAlert}
                    confirmContent="Đồng ý xóa" confirm={this.deleteMyProject}
                />
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    currentUserId: state.auth.id,
    currentUserRandomCode: state.auth.uniq_random_code,
    authorId: state.profile.authorId,
    authorRandomCode: state.profile.authorUniqCode,
    projectsAuthorName: state.profile.projectsAuthorName,
    avatar: state.profile.projectsAuthorAvatar,
    loading: state.profile.loading,
    error: state.profile.error
}
)
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfileByRandomCode: (userRandomCode) => dispatch(getUserProfileByRandomCode(userRandomCode))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfile));