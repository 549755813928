exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".project-detail_projectSmallTasks_2Fxeu {\n    margin-top: -2%!important;\n    margin-bottom: 15px!important;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: justify;\n    -webkit-justify-content: space-between;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    padding-left: 2%;\n    padding-right: 2%;\n}\n\n.project-detail_projectSmallTasks_2Fxeu div {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n}\n\n.project-detail_projectSmallTasksLeft_2U60P {\n    -webkit-box-align: center!important;\n    -webkit-align-items: center!important;\n        -ms-flex-align: center!important;\n            align-items: center!important;\n    -webkit-align-content: center;\n        -ms-flex-line-pack: center;\n            align-content: center;\n}\n\n.project-detail_projectSmallTasksLeft_2U60P h5{\n    margin-left: 5px;\n    margin-top: 5px;\n    font-size: 16px!important;\n    font-weight: bold!important;\n}\n\n.project-detail_joinContestBtn_GjEao {\n    text-decoration: none!important;\n    font-weight: bold;\n}", ""]);

// exports
exports.locals = {
	"projectSmallTasks": "project-detail_projectSmallTasks_2Fxeu",
	"projectSmallTasksLeft": "project-detail_projectSmallTasksLeft_2U60P",
	"joinContestBtn": "project-detail_joinContestBtn_GjEao"
};