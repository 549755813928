import axiosClient from "./axios-client";
// import axiosClient from "./axios-client";


const tutorialsApi = {
    getTutorialsList: (page, per_page) => {
        const url = `/api/v1/codekitten/tutorials?page=${page}&per_page=${per_page}`;
        return axiosClient.get(url)
    }
}
export default tutorialsApi;