
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
class NoneProjectComponent extends Component {

    render() {
        return (
            <div className="c-box">
                <div className="c-box__content">
                    <div className="c-challenges">
                        <div className="c-challenges__content">
                            <div className="c-box">
                                <div className="c-box__title">
                                    <div className="c-box__title__name"><b className="is-medium">Không có dự án</b></div>
                                    <div className="c-box__title__btn"><a className="btn btn-lg btn-primary is-circle" onClick={
                                        () => {
                                            this.props.history.push('/projects/editor')
                                        }
                                    }><b>Tạo dự án ngay</b></a></div>
                                </div>
                            </div>
                        </div>
                        <div className="c-challenges__img"><img src="/static/upload/no-project.png" alt="No project" /></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(NoneProjectComponent);