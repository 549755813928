import {
    FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL,
    CHANGE_FORGOTPASS_PHONENUMBER,
    VERIFY_OTP_FORGOTPASS_REQUEST, VERIFY_OTP_FORGOTPASS_SUCCESS, VERIFY_OTP_FORGOTPASS_FAIL, CLEAR_FORGOT_REDUX
} from "../constants/constants";
import authApi from "../../../api/auth-api";

export const forgotPasswordByPhone = (countryCode, phonenumber) => (dispatch) => {
    dispatch(forgotPassRequest());

    authApi.forgotPasswordByPhone(countryCode + phonenumber).then(response => {
        const status_code = response.message.status_code;
        if (status_code == 200) {
            dispatch(forgotPassSuccess(phonenumber));
        } else {
            let errorAlert = ""
            if(response.error[Object.keys(response.error)[0]] === "Phone number is not valid")
            errorAlert = "Số điện thoại không hợp lệ!";
            else if(response.error[Object.keys(response.error)[0]] === "Phone number is not exists")
            errorAlert = "Số điện thoại chưa đăng ký tài khoản!"
            else if(response.error[Object.keys(response.error)[0]] === "The action you have requested is verry fast. Please try again latter")
            errorAlert = "Thao tác của bạn quá nhanh, vui lòng thử lại sau!"
            else errorAlert = "Đã có lỗi xảy ra vui lòng thử lại sau!";
            dispatch(forgotPassFail(errorAlert));
        }
    }).catch(error => {
        console.log(error)
        dispatch(forgotPassFail("Đã có lỗi xảy ra vui lòng thử lại sau!"));
    })

}

export const clearForgotRedux = () => (dispatch) => {
    dispatch({type: CLEAR_FORGOT_REDUX})
}

export const changeForgotPassPhonenumber = () => (dispatch) => {
    dispatch(changePhonenumber())
}

export const verifyOTPForgotPass = (phonenumber, sms_code) => (dispatch) => {
    dispatch(verifyOTPForgotPassRequest())
    authApi.verifyOTPForgotPass(phonenumber, sms_code).then(response => {
        const status_code = response.message.status_code;
        if (status_code == 200) {
            dispatch(verifyOTPForgotPassSuccess(response.data.forgot_token));
        } else {
            dispatch(verifyOTPForgotPassFail(response.error[Object.keys(response.error)[0]]));
        }

    }).catch(error => {
        console.log(error);
        dispatch(verifyOTPForgotPassFail(error));
    })
}

const forgotPassRequest = () => ({
    type: FORGOT_PASSWORD_REQUEST
})

const forgotPassSuccess = (phonenumber) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    data: {
        phonenumber: phonenumber
    }
})

const forgotPassFail = (error) => ({
    type: FORGOT_PASSWORD_FAIL,
    data: {
        error: error
    }
})
const changePhonenumber = () => ({
    type:CHANGE_FORGOTPASS_PHONENUMBER
})

const verifyOTPForgotPassRequest = () => ({
    type: VERIFY_OTP_FORGOTPASS_REQUEST
})

const verifyOTPForgotPassSuccess = (forgot_token) => ({
    type: VERIFY_OTP_FORGOTPASS_SUCCESS,
    data: { forgot_token: forgot_token }
})

const verifyOTPForgotPassFail = (error) => ({
    type: VERIFY_OTP_FORGOTPASS_FAIL,
    data: { error: error }
})
