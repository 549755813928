exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.join-contest-dialog_closeContestDialogBtn_2oeZW {\n    font-weight: bold!important;\n    position: absolute!important;\n    right: 1%;\n}\n.join-contest-dialog_contest_input_field_1gg5H {\n    border-radius: 6px!important;\n    width: 100%;\n    height: 30px!important;\n    font-size: 16px!important;\n    margin-bottom: 15px!important;\n}", ""]);

// exports
exports.locals = {
	"closeContestDialogBtn": "join-contest-dialog_closeContestDialogBtn_2oeZW",
	"contest_input_field": "join-contest-dialog_contest_input_field_1gg5H",
	"contestInputField": "join-contest-dialog_contest_input_field_1gg5H"
};