import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import CtaButton from '../../../components/codekitten/cta-button.jsx'

class HomeKnowledge extends Component {
    render() {
        return (
        <div className="c-block c-knowledge-block">
          <div className="container">
            <div className="c-box" >
              <div className="c-box__title is-button">
                <div className="c-box__title__name"><span className="is-small">Kiến thức</span><br /><b>Học được</b></div>
                <CtaButton/>
              </div>
              <div className="c-box__content">
                <div className="c-learn-grid">
                  <ul>
                    <li>
                      <div className="c-learn-item">
                        <div className="c-learn-item__title">Khoa học máy tính</div>
                        <div className="c-learn-item__img"><img src="/static/upload/learn_1.jpg" alt="learn" /></div><Link className="c-learn-item__link" to="/tutorials"/>
                      </div>
                    </li>
                    <li>
                      <div className="c-learn-item">
                        <div className="c-learn-item__title">Tư duy logic</div>
                        <div className="c-learn-item__img"><img src="/static/upload/learn_2.jpg" alt="learn" /></div><Link className="c-learn-item__link" to="/tutorials"/>
                      </div>
                    </li>
                    <li>
                      <div className="c-learn-item">
                        <div className="c-learn-item__title">Tư duy thiết kế</div>
                        <div className="c-learn-item__img"><img src="/static/upload/learn_3.jpg" alt="learn" /></div><Link className="c-learn-item__link" to="/tutorials"/>
                      </div>
                    </li>
                    <li>
                      <div className="c-learn-item">
                        <div className="c-learn-item__title">Tư duy sáng tạo</div>
                        <div className="c-learn-item__img"><img src="/static/upload/learn_4.jpg" alt="learn" /></div><Link className="c-learn-item__link" to="/tutorials"/>
                      </div>
                    </li>
                    <li>
                      <div className="c-learn-item">
                        <div className="c-learn-item__title">Kiến thức liên môn STEM</div>
                        <div className="c-learn-item__img"><img src="/static/upload/learn_5.jpg" alt="learn" /></div><Link className="c-learn-item__link" to="/tutorials"/>
                      </div>
                    </li>
                    <li>
                      <div className="c-learn-item">
                        <div className="c-learn-item__title">Thuật toán</div>
                        <div className="c-learn-item__img"><img src="/static/upload/learn_6.jpg" alt="learn" /></div><Link className="c-learn-item__link" to="/tutorials"/>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*end container*/}
        </div>
        )
    }
}
export default HomeKnowledge;
