import React from 'react'

const ContactDetail = () => {
    return (
        <React.Fragment>
            <div className="c-footer-label">Thông tin liên hệ</div>
            <div className="c-footer-content">
                <p className="p-icon"><i className="icon20-phone-white"></i>024 7109 6668<br />Hàng ngày, 8:00 sáng - 10:00 tối</p>
                <p className="p-icon"><i className="icon20-email-white"></i>support@teky.edu.vn</p><br />
				<p><b>Công ty cổ phần công nghệ & sáng tạo trẻ TEKY Holdings</b>, Tầng 5, Tòa nhà Mac Plaza, Số 10 Trần Phú, Hà Đông, Hà Nội</p>
                <p><b>Mã số thuế: </b>0108434588 do Phòng Đăng ký kinh doanh thuộc sở Kế hoạch và Đầu tư cấp lần đầu ngày 14/09/2018</p>
            </div>

        </React.Fragment>
    )
}

export default ContactDetail;
