import React from 'react'


const MoreLinks = () => {
    return (
        <React.Fragment>
            <div className="c-footer-label">Tham khảo</div>
            <div className="c-footer-content">
                <div className="c-footer-list">
                    <ul>
                        <li><a href="https://teky.edu.vn/" target="_blank">Học viện TEKY STEAM</a></li>
                        <li><a href="https://toppy.vn/" target="_blank">TOPPY - Nền tảng giáo dục K12</a></li>
                        <li><a href="https://tutoro.vn/" target="_blank">Cộng đồng Gia sư</a></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MoreLinks;