import React, { Component } from 'react';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx'
import HomeFooter from '../../components/codekitten/home-footer/home-footer.jsx';
import HeaderComponent from '../../components/codekitten/header-component/header-component.jsx';
import RegisterFormContainer from '../../components/codekitten/register-components/register-form-container.jsx';
import { setRegisterSource } from '../../reducers/codekitten/register-reducer/register-source-actions.js';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import styles from './register.css'
class Register extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scroll(0,0)
        if(this.props.loggedIn) {
            this.props.history.push('/')
        }

        const QueryParams = new URLSearchParams(this.props.location.search); 
        const utm_source = QueryParams.get('utm_source');
        if(typeof utm_source !== "undefined" && utm_source !== "" && utm_source !== null) {
            this.props.setRegisterSource(utm_source);
        }
    }
    render() {
        return (
            <>
            <SeoComponent title="Đăng ký"/>
            <HeaderComponent/>
            <div className={styles.registerContent}>
            <RegisterFormContainer/>
            </div>
            <HomeFooter bgColor="#ffffff"/>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRegisterSource: (utm_source) => dispatch(setRegisterSource(utm_source))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));