import React, { Component } from 'react';

class Combatant extends Component {
    render() {
        return (
            <div className="c-combatant-item">
                <div className="c-combatant-item__thumb"><img src={this.props.link_img} alt="combatant" /></div>
                <div className="c-combatant-item__content">
                    <div className="c-combatant-item__row">
                        <div className="c-combatant-item__title">{this.props.name}</div>
                    </div>
                    <div className="c-combatant-item__row">
                        <div className="c-combatant-item__pos">{this.props.age} tuổi , {this.props.school}</div>
                    </div>
                </div>
                <div className="c-combatant-item__desc">{this.props.short_description}</div>
            </div>
        );
    }
}

export default Combatant;