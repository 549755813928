import React, { Component } from 'react';
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx';
import ComponentItemsLoading from '../project-featured/component-featured/item-loading.jsx';
import ComponentItems from '../project-featured/component-featured/component-items.jsx';
import Pagination from './pagination.jsx';
import { getListProject, setCurrentPage, setTitle } from '../../reducers/codekitten/list-projects-reducer/list-projects-actions';
import ComponentListLoading from './component-list-projects/list-projects-loading.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx'

class ListProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //mặc định sẽ lấy nổi bật
            params: {
                page: 1,
                per_page: 16,
                featured: parseInt(process.env.FEATURED_QUERY)
            }
        }
        this.onLoadMore = this.onLoadMore.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)

    }

    onLoadMore() {

        let page = this.props.currentPage < this.props.totalPage ? this.props.currentPage + 1 : this.props.currentPage;
        this.setState({
            params: Object.assign(this.state.params, {
                page: page
            })
        });
        this.props.getListProject(this.state.params);
        this.props.setCurrentPage(page);
    }
    handleOrderBy(value) {
        if (value === 'view' || value === 'play') {
            this.props.setTitle(value === 'view' ? 'Nhiều người xem' : 'Nhiều người chơi')
            this.setState({
                params: Object.assign(this.state.params, {
                    // order_by: value
                })
            });
        }
    }
    handleFeatured(value) {
        if (value === '1' || value === '0') {
            this.props.setTitle('Nổi bật')
            this.setState({
                params: Object.assign(this.state.params, {
                    featured: parseInt(value)
                })
            });
        }

    }
    componentWillMount() {

        var querys = this.getQueryVariable();
        if (querys) {
            switch (querys[0]) {
                case 'order_by':
                    this.handleOrderBy(querys[1]);
                    break;
                case 'featured':
                    this.handleFeatured(querys[1])
                    break;
                case 'theme':
                    this.setState({
                        params: Object.assign(this.state.params, {
                            theme: parseInt(querys[1])
                        })
                    });
                    this.props.setTitle('')
                    break;
                case 'contest':
                    this.setState({
                        params: Object.assign(this.state.params, {
                            contest: parseInt(querys[1])
                        })
                    });
                    this.props.setTitle('Cuộc thi')
                    break;
                default:
                    break;

            }
        }
        
        this.props.getListProject(this.state.params);


    }
    getQueryVariable() {
        var query = window.location.search.substring(1);
        console.log(query)
        var vars = query.split("&");
        console.log(vars)
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)
            if (pair[0] == 'featured' || pair[0] == 'order_by' || pair[0] == 'contest' || pair[0] == 'theme') { return pair; }
        }
        return false;
    }

    renderLoadingItem(perPage) {
        return Array.from({ length: perPage }, () => <ComponentItemsLoading></ComponentItemsLoading>);
    }
    render() {
        const {
            loadingProjects,
            projects,
            totalPage,
            errorProjects,
            currentPage,
            title



        } = this.props
        const titleName=title!==''?title:typeof projects !== 'undefined' && projects.length > 0 && typeof projects[0].themes !== 'undefined' &&  projects[0].themes.length > 0 && typeof projects[0].themes[0].name !== 'undefined' ? projects[0].themes[0].name : '';
        return (
          
            <div>
                <SeoComponent title={`Dự án ${titleName}`}/>
                <HomeTopic name="Dự án"></HomeTopic>
                <div className="l-content">
                    <div className="container">
                        <div className="c-box">
                            <div className="c-box__title">
                                {/* <div>{projects[0].themes[0].name}</div> */}
                                <h2 className="c-box__title__name" ref={this.projectTitleRef}>
                                    <span className="is-small">Dự án</span>
                                    <br />
                                    <b >{titleName}</b>
                                    {/* <b>{`${title} ${typeof projects !== 'undefined' && projects.length > 0 && typeof projects[0].themes !== 'undefined' &&  projects[0].themes.length > 0 && typeof projects[0].themes[0].name !== 'undefined' ? projects[0].themes[0].name : ''} `}</b> */}
                                    </h2>
                            </div>
                            <div className="c-box__content">
                                <div className="c-project-grid">
                                    <ul>
                                        {!loadingProjects && projects.length > 0 ?
                                            projects.map((value) => {
                                                return (<ComponentItems
                                                    projectId={value.project.id}
                                                    userId={value.author.id}
                                                    userCode={value.project.user_code}
                                                    name={value.project.name}
                                                    thumbnail={value.project.thumbnail}
                                                    fullname={value.author.full_name}
                                                    avatar={value.author.avatar}
                                                />)
                                            }) : <ComponentListLoading />
                                        }
                                    </ul>
                                </div>

                                {currentPage < totalPage ? (<a style={{ width: '100%' }} className="btn btn-primary is-circle" onClick={this.onLoadMore}>
                                    <b>Tải thêm</b>
                                </a>) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        loadingProjects: state.listProject.loadingProjects,
        projects: state.listProject.projects,
        totalPage: state.listProject.totalPage,
        currentPage: state.listProject.currentPage,
        errorProjects: state.listProject.errorProjects,
        title: state.listProject.title,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListProject: (params) => dispatch(getListProject(params)),
        setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
        setTitle: (title) => dispatch(setTitle(title)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListProject));
