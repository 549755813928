import React from 'react';
import HomeHeader from '../../components/codekitten/home-header/home-header.jsx';
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx';
import HomeFooter from '../../components/codekitten/home-footer/home-footer.jsx';
import { withRouter } from "react-router-dom";

class NotFound extends React.Component {
  componentDidMount() {
    window.scroll(0,0)
  }
  render() {
    return (
      <div>
        <div>
          <div className="c-pano is-space" />
          <div className="c-block">
            <div className="container">
              <div className="c-challenges">
                <div className="c-challenges__content">
                  <div className="c-box">
                    <div className="c-box__title">
                      <div className="c-box__title__name"><span className="is-small">404</span><br /><b className="is-medium">Không tìm thấy trang</b></div>
                      <div className="c-box__title__btn"><a className="btn btn-lg btn-primary is-circle" onClick={
                        () => {
                          this.props.history.push('/')
                        }
                      }>
                        Quay lại&nbsp;
                        <b>Trang chủ</b></a></div>
                    </div>
                  </div>
                </div>
                <div className="c-challenges__img"><img src="/static/upload/404.png" alt={404} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

};

export default withRouter(NotFound);
