// Polyfills
import 'es6-object-assign/auto';
import 'core-js/fn/array/includes';
import 'core-js/fn/promise/finally';
import 'intl'; // For Safari 9
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import  {Provider}  from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import App from '../views/app.jsx';

import {store, persistor} from "../reducers/codekitten/store";
const theme = createTheme({
    typography: {
      fontFamily: "'Quicksand', sans-serif"
    },
  });
ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
        <App/>
        </PersistGate>
        </ThemeProvider>
    </Provider>
    ,
    document.getElementById('mainApp')
);
