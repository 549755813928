import React, { Component } from 'react';
import styles from '../project-featured.css';
import { Link, withRouter } from "react-router-dom";
import DefaultAvatar from '../../../components/codekitten/default-avatar/default-avatar.jsx';
class ComponentItems extends Component {
    constructor(props) {
        super(props);
        this.clickViewTutorial = this.clickViewTutorial.bind(this);
    }

    clickViewTutorial() {
        console.log("clickViewTutorial");
    }

    clickPlayNow() {
        this.props.history.push(`/projects/${this.props.projectId}`);
    }
    
    render() {
        return (
            <li>
                <div className="c-project-item" >
                    <div className="c-project-item__thumb">
                        <Link to={`/projects/${this.props.projectId}`}><img src={this.props.thumbnail === "" || this.props.thumbnail == null ? "/static/images/thumbnails/default-thumbnail.png" : this.props.thumbnail} alt="project" /></Link>
                    </div>
                    <div className="c-project-item__content">
                        <div className="c-project-item__avatar">
                            <Link style={{ textDecoration: 'none' }} to={`/users/${this.props.userCode}/projects`}>
                                <DefaultAvatar fullname={this.props.fullname} avatar={this.props.avatar} alt="avatar" />
                            </Link>
                        </div>
                        <div className="c-project-item__row">
                            <h3 className="c-project-item__title">
                                <Link to={{ pathname: `/projects/${this.props.projectId}` }} >
                                    <div className={styles.hoverText}>{this.props.name}</div>
                                </Link>
                            </h3>
                            <span className="c-project-item__author">
                                Bởi: <b className={styles.cProjectItemAuthorB} onClick={() => {
                                    this.props.history.push(`/users/${this.props.userCode}/projects`);
                                }} >{this.props.fullname}</b>
                            </span>
                        </div>
                        <div className="c-project-item__row">
                            <Link className={"c-project-item__link " + styles.pointerPlay} to={{
                                pathname: `/projects/${this.props.projectId}`,
                                state: { fullScreen: true }
                            }}  >Chơi ngay</Link>
                            {/* <a className="c-project-item__link" onClick={this.clickViewTutorial} href="project-detail.html">Xem hướng dẫn</a> */}
                            {this.props.isInMyProfile ? (
                                <>
                                    {/* <Link className={styles.pointerPlay+" c-project-item__link"} >Chia sẻ</Link> */}
                                    <Link className={styles.pointerPlay + " c-project-item__link"} onClick={this.props.deleteMyProjectConfirm}>Xóa</Link><br />
                                    {/* <Link className={styles.pointerPlay+" c-project-item__link mt-lg-3"} >Tham gia cuộc thi</Link> */}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </li >
        );
    }
}
export default withRouter(ComponentItems);