import React, { Component } from 'react'

export default class HomeHead extends Component {
    render() {
        return (
           <div className="c-head">
            <div className="container">
                <div className="c-head-main">
                <div className="c-head-main__left">
                    <h1 className="c-head-main__title"><span className="is-small">Thắp Lửa Đam Mê</span><br />Lập trình</h1>
                    <h2 className="c-head-main__desc"><b className="is-medium">Hoàn toàn miễn phí</b><br />Tạo, chơi &amp; học từ
                    <b>&nbsp;200 video hướng dẫn&nbsp;</b>
                    và các dự án
                    </h2>
                </div>
                <div className="c-head-main__right">
                    <div className="c-head-main__hint"><b className="is-medium">+20.000</b>
                    &nbsp;học sinh toàn quốc đang dùng
                    </div>
                </div>
                </div>
            </div>
            <div className="c-head-img"><img src="/static/upload/head.png" alt="head" /></div>
            {/*end container*/}
            </div>
        )
    }
}
