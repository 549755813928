import React, { Component } from 'react';
import Home from "./home/home.jsx";
import ProjectFeatured from "./project-featured/project-featured.jsx";
import ListProject from './list-project/list-project.jsx';
import NotFound from "./not-found/not-found.jsx";
import Register from "./authen/register.jsx";
import Login from "./authen/login.jsx";
import Forgotpass from './authen/auth-forgotpass.jsx';
import UserProfile from './user-homepage/user-profile.jsx';
import Tutorials from './tutorialspage/tutorials.jsx';
import Challenges from './challenges-page/challenges.jsx';
// import Challenging from './challenging/challenging.jsx';
import Stages from './challenges-page/stages.jsx';
import BaseLayout from "../common/BaseLayout.jsx";
import WrapperGUIGetStartedComponent from "../playground/wrapped-gui-get-started.jsx";
import WrapperGUIComponent from "../playground/wrapped-gui.jsx";
import ProjectDetail from './project-detail/project-detail.jsx';
import AppStateHOC from '../lib/app-state-hoc.jsx';
import Challenging from '../views/challenging/challenging.jsx'
import GUI from '../containers/gui.jsx';
import { compose } from 'redux';
import HashParserHOC from '../lib/hash-parser-hoc.jsx';
import { createBrowserHistory } from "history";
import { getUserProfile } from '../reducers/codekitten/auth-reducer/auth-actions.js';
import { connect } from 'react-redux';
import { expireRegisterSource } from '../reducers/codekitten/register-reducer/register-source-actions.js';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
    useParams,
    useRouteMatch,
    BrowserRouter,
} from "react-router-dom";
const history = createBrowserHistory();
const WrappedGuiGetStarted = compose(
    AppStateHOC,
    HashParserHOC
)(GUI);
class App extends Component {
    componentDidMount() {
        if(!(this.props.token === "")){
        this.props.getUserProfile(this.props.token)
        }
        this.props.expireRegisterSource();
    }
    componentWillUnmount(){
    }
    render() {
        const token= this.props.token;
        return (
            <BrowserRouter history={history}>
                <Switch>
                    <RouteWrapper exact path="/" component={Home} layout={BaseLayout} />
                    <Route path="/register" component={Register}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/forgotpassword" component={Forgotpass}/>
                    <RouteWrapper exact path="/projects" component={ProjectFeatured} layout={BaseLayout} />
                    <RouteWrapper exact path="/list-project" component={ListProject} layout={BaseLayout} />
                    <RouteWrapper exact path="/tutorials" component={Tutorials} layout={BaseLayout} />
                    <RouteWrapper exact path="/challenges" component={Challenges} layout={BaseLayout}/>
                    <RouteWrapper exact path="/challenges/:challenges_url" component={Challenging} layout={BaseLayout}/>
    
                    <Route path="/projects/editor" strict render={(props)=>
                    {
                        var projectId=0;
                        console.log(token);
                        if( typeof props.location !== "undefined" && typeof props.location.state !== "undefined" && typeof props.location.state.projectId!== "undefined"){
                            projectId=props.location.state.projectId;
                        }
                        return (<WrapperGUIGetStartedComponent> 
                         <WrappedGuiGetStarted
                            canSave={false}
                            projectId={projectId}
                            assetHost={`${process.env.BASE_URL}/api/v1/codekitten/assets`}
                            projectHost={`${process.env.BASE_URL}/api/v1/codekitten/projects`}
                            isStarted={false}
                        />
                        </WrapperGUIGetStartedComponent>)}}/>
                    <RouteWrapper path="/projects/:projectId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})" exact component={ProjectDetail} layout={BaseLayout}/>
                    <RouteWrapper path="/users/:UniqRandomCode/projects" exact
                        component={UserProfile} layout={BaseLayout}/>
                    <Route path="/projects/:projectId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/editor" 
                    render={(props)=>{
                        console.log('Load trang project theo id')
                        // setTimeout(function () {
                        //     window.location.reload();
                        // }, 100);
                        return <WrapperGUIComponent></WrapperGUIComponent>
                    }}
                    
                    // component={WrapperGUIComponent}
                     />
                    <RouteWrapper path="*" component={NotFound} layout={BaseLayout}></RouteWrapper>
                </Switch>
            </BrowserRouter>
        );
    }
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props}>
                    <Component {...props} />
                </Layout>
            )}
        />
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
})
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (token) => dispatch(getUserProfile(token)),
        expireRegisterSource: () => dispatch(expireRegisterSource())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
