import React, { Component } from 'react'
import CtaButton from '../../../components/codekitten/cta-button.jsx'

class HomeChallenge extends Component {
    render() {
        return (
            <div className="c-block c-challenges-block">
            <div className="container">
                <div className="c-challenges">
                <div className="c-challenges__content">
                    <div className="c-box">
                    <div className="c-box__title">
                        <div className="c-box__title__name"><span className="is-small">Thử thách</span><br /><b>Làm game</b></div>
                        <div className="c-box__title__desc">Tham gia những thử thách sáng tạo lập trình Game thú vị cùng hàng trăm nghìn bạn nhỏ trên toàn quốc. Thách thức sự sáng tạo của bé!</div>
                        <CtaButton />
                    </div>
                    </div>
                </div>
                <div className="c-challenges__img"><img src="/static/upload/challenges.png" alt="challenges" /></div>
                </div>
            </div>
            {/*end container*/}
            </div>
        )
    }
}
export default HomeChallenge;
