import React, { Component } from 'react'
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import styles from "../profile-page.css";
import DefaultAvatar from '../../../components/codekitten/default-avatar/default-avatar.jsx';
import { TextField } from '@material-ui/core';
import { updateUserInfo } from "../../../reducers/codekitten/profile-reducer/userprofile-actions";
import profileApi from '../../../api/profile-api';
class UpdateUserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullname: this.props.fullname,
            bio: this.props.bio,
            birthday: this.props.birthday,
            provinces: this.props.dvhc,
            districts: this.props.districts,
            schools: [],
            disableSchoolsField: true,
            country: "01",
            province: this.props.province,
            district: this.props.district,
            school: this.props.school,
            loadingSchools: false,
            today: ""
        }
    }

    componentDidMount() {
        const today = new Date();
        let day = String(today.getDate()).padStart(2, '0');
        let month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let year = today.getFullYear().toString();
        this.setState({ today: year + "-" + month + "-" + day })
        if (this.props.district !== "" && this.props.district !== null) {
            this.getSchools(this.props.district)
        }
    }

    getSchools = (district) => {
        this.setState({loadingSchools: true})
        profileApi.getSchoolsByDistrictCode(district).then(response => {
            if(response.data.length > 0)
            this.setState({ disableSchoolsField: false, schools: response.data, school: response.data[0].code })

            this.setState({ loadingSchools: false})
        }).catch(error => {
            console.log(error)
            this.setState({ loadingSchools: false, disableSchoolsField: false})
        })
    }

    handleChangeDate = (event) => {
        let value = event.target.value;
        this.setState({ birthday: value })
    }

    handleBlurDate = (event) => {
        let date = event.target.value;
        let year = date.substring(0, 4);
        let month = date.substring(5, 7)
        let day = date.substring(8, 10);
        if (year > this.state.today.substring(0, 4)) {
            this.setState({ birthday: this.props.birthday })
            event.target.value = this.props.birthday;
        } else if (year === this.state.today.substring(0, 4)) {
            if (month > this.state.today.substring(5, 7)) {
                this.setState({ birthday: this.props.birthday })
                event.target.value = this.props.birthday;
            } else if (month === this.state.today.substring(5, 7)) {
                if (day > this.state.today.substring(8, 10)) {
                    this.setState({ birthday: this.props.birthday })
                    event.target.value = this.props.birthday;
                }
            }
        }
    }

    handleChangeText = (event) => {
        let value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value
        });
    };

    handleBlurText = (event) => {
        let value = event.target.value;
        const name = event.target.name;

        while (value.charAt(0) === " ") value = value.substring(1, value.length);
        while (value.charAt(value.length - 1) === " ") value = value.substring(0, value.length - 1);
        if (value === "") {
            if (name === 'fullname')
                this.setState({
                    [name]: this.props.fullname
                })
            else if (name === "birthday")
                this.setState({
                    [name]: this.props.birthday
                })
        } else {
            this.setState({
                [name]: value
            })
        }

    };

    handleChange = (event) => {
        const name = event.target.name;
        const selectedIndex = event.target.options.selectedIndex;
        this.setState({
            [name]: event.target.options[selectedIndex].getAttribute('keyData'),
        });
        if (name === "province") {
            if (selectedIndex == 0) { // select none
                this.setState({
                    districts: [],
                    district: '',
                    province: '',
                    school: '',
                    schools: []
                })
                return;
            }
            document.getElementById("district").value = "";
            this.setState({
                district: "",
                school: '',
                schools: [],
                districts: JSON.parse(event.target.options[selectedIndex].getAttribute('json')),
                disableSchoolsField: true
            })
        } else if(name === "district") {
            this.setState({
                school: "",
                schools: [],
                disableSchoolsField: true
            })
            this.getSchools(event.target.options[selectedIndex].getAttribute('keyData'))
        }
    };

    updateInfo = () => {
        const data = {
            fullname: this.state.fullname,
            bio: this.state.bio,
            country: this.state.country,
            province: this.state.province,
            district: this.state.district,
            school: this.state.school,
            birthday: this.state.birthday === "" ? null : this.state.birthday
        }
        console.log(data.fullname + " " + data.country + " " + data.province + " " + data.district + " " + data.birthday + " " + data.school)
        this.props.updateUserInfo(data);
    }

    render() {
        const { avatar, fullname, bio, country, provinceName, districtName, schoolName, birthday } = this.props;
        return (
            <div className="c-info-box">
                <form autoComplete="off">
                    <div className="c-info-box__title">Thông tin cá nhân&nbsp;
                    </div>

                    <div className="c-info-box__content">
                        <div className="row">
                            <div className="col-md-6 pr-md-5">
                                <div className="b-grid mb-3">
                                    <div className="b-grid__img">
                                        <figure><DefaultAvatar fullname={fullname} avatar={avatar} /></figure>
                                    </div>
                                    <div className="b-grid__content">
                                        <div className="b-grid__row">
                                            <h1 className="b-grid__title">
                                                <TextField id="outlined-basic" label="Họ và tên" variant="outlined" name="fullname"
                                                    inputProps={{ style: { fontFamily: `"Quicksand", "sans-serif"`, fontWeight: "bolder" } }}
                                                    InputLabelProps={{ style: { fontFamily: `"Quicksand", "sans-serif"`, color: 'black' } }}
                                                    className={styles.update_info_inputfield} value={this.state.fullname}
                                                    onChange={this.handleChangeText} onBlur={this.handleBlurText} />
                                            </h1>
                                            <div className="b-grid__row">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header">
                                        <h5>Giới thiệu về tôi</h5>
                                    </div>


                                    <div className="">
                                        {/* {bio !== null && bio !== "" ? bio : "..."} */}
                                        <div className={"form-group m-0 " + styles.update_info_inputfield}>
                                            <textarea name="bio" className={"form-control " + styles.update_info_inputfield} id="bio" rows={3}
                                                value={this.state.bio} onChange={this.handleChangeText} onBlur={this.handleBlurText} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 pl-md-3 pr-md-5">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Ngày Sinh:
                                        <div className="float-right">
                                            <div className="form-group m-0">
                                                <input name="birthday" type="date" className={"form-control " + styles.update_info_inputfield}
                                                    id="birthday" defaultValue={birthday} onChange={this.handleChangeDate} onBlur={this.handleBlurDate}
                                                    min="1900-01-01" max={this.state.today} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <label htmlFor="country">Quốc gia:</label>

                                        <div className="float-right">
                                            {/* {country !== null && country !== "" ? country : "..."} */}
                                            <div className="form-group m-0">
                                                <select name="country" onChange={this.handleChange} className={"form-control m-0 " + styles.update_info_inputfield} id="country" defaultValue={country}>
                                                    <option keyData="01">Việt Nam</option>
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        Tỉnh/Thành phố:
                                        <div className="float-right">
                                            {/* {province !== null && province !== "" ? province : "..."} */}
                                            <div className="form-group m-0">
                                                <select name="province" onChange={this.handleChange} className={"form-control m-0 " + styles.update_info_inputfield} id="province" defaultValue={provinceName}>
                                                    <option keyData=''></option>
                                                    {this.state.provinces.map((province, index) => (
                                                        <option key={index} keyData={province.code} json={JSON.stringify(province.districts)}>{province.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        Quận/Huyện:
                                        <div className="float-right">
                                            {/* {district !== null && district !== "" ? district : "..."} */}
                                            <div className="form-group m-0">
                                                <select name="district" onChange={this.handleChange} className={"form-control m-0 " + styles.update_info_inputfield} id="district" defaultValue={districtName}>
                                                    <option keyData=''></option>
                                                    {this.state.districts.map((district, index) => (
                                                        <option key={index} keyData={district.code}>{district.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    {this.state.schools.length >0 ? <li className="list-group-item">
                                        Trường học:
                                        <div className="float-right">
                                            {/* {district !== null && district !== "" ? district : "..."} */}
                                            <div className="form-group m-0">
                                                <select disabled={this.state.disableSchoolsField} name="school" onChange={this.handleChange} className={"form-control m-0 " + styles.update_info_inputfield} id="school" defaultValue={schoolName}>
                                                    {this.state.schools.map((school, index) => (
                                                        <option key={index} keyData={school.code}>{school.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </li> : null}
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="display-inline-block row">
                                {/* <div className="col-md-8">
                                </div> */}
                                <div className="col-12">
                                    {this.props.error === "update profile failed!" ? <div className="float-left text-danger mt-2 text-center">Đã có lỗi xảy ra, vui lòng thử lại sau</div> : null}
                                    {this.props.waitForUpdateInfo || this.state.loadingSchools ? <Loader className="float-right" className="TailSpin" type="Rings" color="#ffa31a" height={100} width={100} /> :
                                        <><Button className="float-right mr-4" variant="primary" onClick={this.updateInfo}>Cập nhật</Button>
                                            <Button className="float-right mr-2" variant="secondary" onClick={this.props.backToHomepage}>Hủy</Button>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    error: state.profile.updateInfoError,
    waitForUpdateInfo: state.profile.waitForUpdateInfo,
    dvhc: state.profile.dvhc
})
const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (data) => dispatch(updateUserInfo(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserInfo);