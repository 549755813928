import React from 'react';

import AboutUs from './about-us.jsx';
import MoreLinks from './more-links.jsx';
import ContactDetail from './contact-detail.jsx';

const CopyRight = () => {
    return (
        <React.Fragment>
            <div className="c-copyright"><a className="text-decoration-none text-white" href="https://teky.edu.vn/" target="_blank">Một sản phẩm của Học viện Teky</a></div>
            <div className="c-footer-social"><a href="https://www.facebook.com/tekyacademy" target="_blank"><i className="icon16-facebook-white" /></a></div>
        </React.Fragment>
    )
}

const HomeFooter = () => {
    return (
        <div className="l-footer">
            <div className="container">
                <div className="c-footer-main">
                    <div className="row">
                        <div className="col-lg-5 mb-3 mb-lg-0">
                            <div className="c-footer-logo"><a href="#"><img src="/static/images/logo.png" alt="logo" /></a></div>
                            <div className="c-footer-desc">
                                <p>Lấy cảm hứng từ Scratch, một ngôn ngữ lập trình trực quan (VPL) do MIT phát triển, CodeKitten tự hào là nền tảng lập trình VPL đầu tiên cho trẻ em tại Việt Nam, được xây dựng bởi đội ngũ kỹ sư, chuyên gia hàng đầu tại Học viện Teky</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-md-4">
                                    <AboutUs></AboutUs>
                                </div>
                                <div className="col-md-4">
                                    <MoreLinks></MoreLinks>
                                </div>
                                <div className="col-md-4">
                                    <ContactDetail></ContactDetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-footer-bottom">
                    <CopyRight></CopyRight>
                </div>
            </div>
        </div>
    );
};
export default HomeFooter;
