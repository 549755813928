/**Danh sach du an o HOME */
import axiosClient from "./axios-client";

const homeApi = {
    getNews: (per_page) => {
        return axiosClient.get("/wp-json/wp/v2/posts?per_page=" + per_page + "&_embed",{
            baseURL: process.env.BLOG_POSTS_JSON_URL
        })
    }
}

export default homeApi;