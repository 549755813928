import React, { Component } from 'react'
import RequestButton from '../../../components/codekitten/request-btn/request-btn.jsx';
class ForgotpassFirstStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basicError: "",
            loading: false
        }
        this.submit= this.submit.bind(this);
    }
    checkPhonenumber(e) {
        let value = e.target.value;
        if (value.length == 1 && (value == "0" || value == 0 || (/[a-zA-Z]/).test(value))) {
            e.target.value = null;
            return;
        }
        var clearedNumberStr = "";
        if (value.length > 50) value = value.substring(0, 50);
        for (var i = 0; i < value.length; i++) {
            if (!isNaN(value.charAt(i)))
                clearedNumberStr += value.charAt(i);
        }

        while (clearedNumberStr.charAt(0) === '0') clearedNumberStr = clearedNumberStr.substring(1, clearedNumberStr.length)

        if (clearedNumberStr.length > 12) {
            e.target.value = clearedNumberStr.substring(0, 12);
        } else {
            e.target.value = clearedNumberStr;
        }
        return;
    };

    submit(e) {
        e.preventDefault();
        const inpPhonenumber = document.getElementById("forgot-phonenumber");
        let phonenumber = inpPhonenumber.value;

        if (phonenumber.length == 0) {
            document.getElementById('forgot-phonenumber').focus();
            this.setState({
                basicError: "Vui lòng nhập số điện thoại!"
            })
            return;
        }
        if (phonenumber.length < 9) {
            document.getElementById('forgot-phonenumber').focus();
            this.setState({
                basicError: "Vui lòng nhập số điện thoại có thực!"
            })
            return;
        }
        this.setState({
            basicError: ""
        })
        this.props.submitFirstStep(phonenumber);
    }
    componentDidUpdate() {
        if(this.state.loading !== this.props.waitingForResponse) {
            if(this.props.waitingForResponse == false) {
                document.getElementById('forgot-phonenumber').focus();
            }
            this.setState({loading: this.props.waitingForResponse})
        }
    }

    render() {
        return (
            <form onSubmit={this.submit}>
            <div className="c-auth-form">
                <div className="form-group">
                <div className="input-group is-flag">
                    <div className="input-group-prepend">
                    <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="c-flag" src="/static/images/flags/vn.svg" alt="vn" /><span className="btn-text"><span className="small">Số điện thoại</span>+84</span></button>
                    <div className="dropdown-menu"><a className="dropdown-item" href="#"><img className="c-flag" src="/static/images/flags/vn.svg" alt="vn" />+84 Việt Nam</a></div>
                    </div>
                    <input id="forgot-phonenumber" className="form-control" type="tel" onChange={e => this.checkPhonenumber(e)} defaultValue={this.props.phonenumber}/>
                </div> 
                </div>
                <p id="alert-on-submit" className="text-danger">{this.state.basicError}</p>
                <div className="text-danger">{this.props.error}</div>
                <div className="form-group is-button">
                <RequestButton waitingForResponse={this.props.waitingForResponse} content="Gửi yêu cầu qua điện thoại" onClick={this.submit}/>
                </div>
            </div>
            </form>
        )
    }
}

export default ForgotpassFirstStep;