import React, { Component } from 'react';

class Teacher extends Component {
    render() {
        return (
            <div className="c-free-item">
                <div className="c-free-item__thumb"><img src={this.props.link_img} alt="teacher" /></div>
                <div className="c-free-item__content">
                    <div className="c-free-item__row"><a target="_blank" rel="noopener noreferrer" className="c-free-item__facebook" href={this.props.link_facebook}>
                        {/* <i className="icon36-facebook-white" /> */}
                    </a>
                        <div className="c-free-item__title">{this.props.name}</div>
                    </div>
                    <div className="c-free-item__row">
                        <div className="c-free-item__pos">{this.props.job}</div>
                    </div>
                    <div className="c-free-item__desc">
                        {/* <p>Lorem ipsum</p>
                            <p>Lorem ipsum</p>
                            <p>Lorem ipsum</p> */}
                            {this.props.short_description.map((desc, index) => (
                                <p key={"teacher_desc_" + index}>{desc}</p>
                            ))}
                        {/* {this.props.short_description} */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Teacher;