import {GET_PROJECT_DATA_FILE_REQUEST,GET_PROJECT_DATA_FILE_SUCCESS,GET_PROJECT_DATA_FILE_FAIL} from "../constants/constants";
import projectsApi from "../../../api/projects-api";

export const getProjectJsonFile = (idProject) => (dispatch,getState) => {
    dispatch({
        type:GET_PROJECT_DATA_FILE_REQUEST
    })
   return projectsApi.getProjectDataFile(idProject).then(res=>{
        
      
       let projectJson=JSON.stringify(res);
        console.log(projectJson);
        dispatch({
            type:GET_PROJECT_DATA_FILE_SUCCESS,
            data:{projectJson}
        })
        return  Promise.resolve(getState());
    }).catch((error) => {
        dispatch({
            type:GET_PROJECT_DATA_FILE_FAIL,
            data:{error}
        })
        return Promise.resolve(getState());
    })
    

}