import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from "react-router-dom";

import { Component } from 'react';

class HomeMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menus: [
				{
					name: 'Trang chủ',
					path: '/',
					pattern: '/'
				},
				{
					name: 'Thử Thách',
					path: '/challenges',
					pattern: '/^\/challenges/'
				},
				{
					name: 'Dự án',
					path: '/projects',
					pattern: '/^\/projects/'
				},
				{
					name: 'Kiến thức',
					path: '/tutorials',
					pattern: '/^\/tutorials/'
				}
			]
		}
	}

	render() {
		const { location } = this.props;
		return (
			<div className="c-menu">
				<ul>
					{this.state.menus.map((value, index) => {
						if (location.pathname === `${value.path}`) {
							return (
								<li className="active" key={index}>
									<Link to={{ pathname: `${value.path}` }}>{value.name}</Link>
								</li>
							)
						} else {
							return (
								<li key={index}>
									<Link to={{ pathname: `${value.path}` }}>{value.name}</Link>
								</li>
							)
						}
					})
					}
					<li>
						<a href="https://codekitten.vn/blog/" target="_blank">Blog</a>
					</li>
				</ul>
			</div>
		);
	}
}

// const getParams = (pathname) => {
// 	const matchProfile = matchPath(pathname, {
// 		path: `/profile/:profileId`,
// 	});
// 	return (matchProfile && matchProfile.params) || {};
// };

export default withRouter(HomeMenu);