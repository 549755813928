import React, { Component } from 'react'
import challenges from "../../lib/libraries/challenge/challenges-list.json";
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx';
import ComponentItemsLoading from '../project-featured/component-featured/item-loading.jsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import { getProjectJsonFile } from '../../reducers/codekitten/data-project-reducer/data-project-action';
import ArticleComponent from '../../components/codekitten/tutorials-pagination/article-component.jsx';
import NotFound from '../not-found/not-found.jsx';
class Stages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            challenges_url: this.props.match.params.challenges_url,
            challenge: {},
        }
        this.onClickItem = this.onClickItem.bind(this);
    }

    componentDidMount() {
        window.scroll(0, 0);
        for (const e of challenges) {
            if (e.url === this.state.challenges_url)
                this.setState({ loading: false, challenge: e })
        }
        this.setState({ loading: false })
    }
    onClickItem(idTutorial, idProject) {

        if (idProject !== '') {
            this.props.getProjectJsonFile(idProject).then(newState => {
                if (newState.dataProjectJson.error === '') {
                    this.props.history.push(`/projects/editor?tutorial=${idTutorial}`, { tutorial: `?tutorial=${idTutorial}`, projectId: `${idProject}`, projectJson: newState.dataProjectJson.projectJson })
                }
            });
        } else {
            this.props.history.push(`/projects/editor?tutorial=${idTutorial}`, { tutorial: `?tutorial=${idTutorial}` })

        }



    }
    render() {
        if (typeof this.state.challenge.stages === "undefined" && !this.state.loading) {
            return <NotFound />
        }
        return (

            <>
                <HomeTopic name={this.state.challenge.name} />
                <div className="l-content">
                    <div className="container">
                        <div className="c-box__title__btn mb-5">
                            <Link className="btn btn-lg btn-primary is-circle" to="/challenges">
                                <ArrowBackIcon /><b>Quay lại</b>
                            </Link>
                        </div>
                        <div className="c-knowledge-grid">
                            {!this.state.loading ?
                                (<ul>
                                    {this.state.challenge.stages.map((stage, index) => (
                                        // lấy stage project id với totorial id ở đây
                                        <li key={index}>
                                            <ArticleComponent title={stage.name} description={stage.description} thumbnail={stage.img} onClick={() => {
                                                this.onClickItem(stage.tutorial_id, stage.project_id);

                                            }} />
                                        </li>
                                    ))}
                                </ul>)
                                :
                                (<ul id=""><li>
                                    <ComponentItemsLoading></ComponentItemsLoading>
                                </li>
                                    <li>
                                        <ComponentItemsLoading></ComponentItemsLoading>
                                    </li>
                                    <li>
                                        <ComponentItemsLoading></ComponentItemsLoading>
                                    </li> </ul>)}
                        </div>
                    </div>
                    {/*end container*/}
                </div>
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({

    getProjectJsonFile: (idProject) => dispatch(getProjectJsonFile(idProject))
})
export default connect(null, mapDispatchToProps)(withRouter(Stages));