import React, { Component } from 'react';
import Combatant from './combatant-component.jsx';

import CombatantList from "../../../../src/lib/libraries/homepage/combatants.json";
import CtaButton from '../../../components/codekitten/cta-button.jsx';

class Combatants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            combatants: []
        }
    }

    componentDidMount() {
        this.setState({
            combatants: CombatantList
        })
    }
    render() {
        return (
        <div className="c-block c-combatant-block">
          <div className="container">
            <div className="c-box">
              <div className="c-box__title is-button">
                <div className="c-box__title__name"><span className="is-small">Codekitten</span><br /><b>Chiến binh</b></div>
                <CtaButton/>
              </div>

              <div className="c-box__content">
                       <div className="c-combatant-slider">
                           <div className="owl-carousel js-combatant-slider">
                               {this.state.combatants.map((combatant, index) => (
                                <Combatant key={index} name={combatant.full_name} age={combatant.age}
                                link_img={combatant.link_img} school={combatant.school}
                                short_description={combatant.short_description}></Combatant>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
          </div>
          {/*end container*/}
        </div>
        );
    }
}

export default Combatants;