import React, { Component } from 'react'
import CtaButton from '../../../components/codekitten/cta-button.jsx'

export default class HomeTry extends Component {
    render() {
        return (
            <div className="c-block c-try-block">
            <div className="container">
                <div className="c-box">
                <div className="c-box__title">
                    <div className="c-box__title__name"><span className="is-small">Thử ngay</span><br /><b>Game mới</b></div>
                    <div className="c-box__title__desc">
                    <p>Hãy là người đầu tiên chơi và bình luận game của các bạn nhỏ khác</p>
                    </div>
                    <CtaButton/>
                </div>
                <div className="c-box__content">
                    <div className="b-maincontent">
                    <p><img src="/static/upload/try_1.png" alt="try" /></p>
                    </div>
                </div>
                </div>
                <div className="c-box">
                <div className="c-box__title">
                    <div className="c-box__title__name"><span className="is-small">Là một phần của</span><br /><b>Những điều lớn lao</b></div>
                    <div className="c-box__title__desc">
                    <p>Không chỉ là nền tảng học lập trình game, bạn còn được tham gia vào cộng đồng lập trình nhí lớn nhất Việt Nam - những nhà sáng tạo đổi mới của tương lai</p><br />
                    <ul>
                        <li>Cùng học</li>
                        <li>Cùng chia sẻ, làm bạn</li>
                        <li>Cùng thi đấu</li>
                        <li>Cùng kết nối ước mơ</li>
                    </ul>
                    </div>
                    <CtaButton/>
                </div>
                <div className="c-box__content">
                    <div className="b-maincontent">
                    <p><img src="/static/upload/try_2.png" alt="try" /></p>
                    </div>
                </div>
                </div>
            </div>
            {/*end container*/}
            </div>
        )
    }
}
