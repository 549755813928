
import React from 'react'
import HomeHeader from '../components/codekitten/home-header/home-header.jsx';
import HomeFooter from '../components/codekitten/home-footer/home-footer.jsx';

    function BaseLayout(props) {
        return (
            <>
            
                <HomeHeader />
               
                {props.children}
                <HomeFooter />
            </>
        )
    }

export default BaseLayout
