import React, { Component } from 'react';
import PaginationNav from './pagination-nav.jsx';
import ArticleComponent from "./article-component.jsx"
import ComponentItemsLoading from '../../../views/project-featured/component-featured/item-loading.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getProjectJsonFile } from '../../../reducers/codekitten/data-project-reducer/data-project-action';
class PageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: [],
            total_page: 0,
        }
        this.onClickItem = this.onClickItem.bind(this);
    }


    onClickItem(idTutorial, idProject) {
        // idProject="e22c079e-2cd7-4044-bdc5-59d0111c52f1";
        this.props.showLoading();
        if (idProject !== '') {
            this.props.getProjectJsonFile(idProject).then(newState => {
                if (newState.dataProjectJson.error === '') {
                    this.props.history.push(`/projects/editor?tutorial=${idTutorial}`, { tutorial: `?tutorial=${idTutorial}`, projectJson: newState.dataProjectJson.projectJson })
                }
                this.props.hideLoading();
            });
        } else {
            this.props.history.push(`/projects/editor?tutorial=${idTutorial}`, { tutorial: `?tutorial=${idTutorial}` })
            this.props.hideLoading();
        }



    }
    componentWillUnmount() {
        this.props.hideLoading();
    }

    render() {
        return (
            <div>
                <div className="c-knowledge-grid">

                    {/* LOADING */}
                    {!this.props.loading ?
                        (<ul>
                            {this.props.tutorialList.map((article, index) => (
                                <li key={index}>
                                    <ArticleComponent
                                        onClick={() => this.onClickItem(article.id, article.project_id)}
                                        title={article.name} description={article.description} thumbnail={article.thumbnail}></ArticleComponent>
                                </li>
                            ))}
                        </ul>)
                        :
                        (<ul id=""><li>
                            <ComponentItemsLoading></ComponentItemsLoading>
                        </li>
                            <li>
                                <ComponentItemsLoading></ComponentItemsLoading>
                            </li>
                            <li>
                                <ComponentItemsLoading></ComponentItemsLoading>
                            </li> </ul>)}
                </div>
                <PaginationNav moveToPage={this.props.moveToPage} total_page={this.props.total_page} activePage={this.props.onPage}></PaginationNav>
                {/* b-page*/}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn
})
const mapDispatchToProps = (dispatch) => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    getProjectJsonFile: (idProject) => dispatch(getProjectJsonFile(idProject))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageComponent));
