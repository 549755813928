
import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Interweave from 'interweave';
import styles from "./dialog-styles.css";

class ConfirmDialog extends Component {
    render() {
        return (
            <>
                 <Dialog
                    open={this.props.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle disableTypography={true} className={styles.dialog_header} id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Interweave className={styles.dialog_font_family} disableTypography="true" content={this.props.description}/>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.props.cancel} className={styles.dialog_font_family} variant="outlined" >
                        {this.props.cancelContent}
                    </Button>
                    <Button className={styles.dialog_font_family} onClick={this.props.confirm} color="secondary" variant="outlined" >
                        {this.props.confirmContent}
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default ConfirmDialog;
