

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { creatProject } from '../reducers/codekitten/gui-reducer/gui-actions';
import { Redirect } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import Loader from "react-loader-spinner";
import { updateAuthInEditor } from "../reducers/codekitten/auth-reducer/auth-actions.js";
import { setRegisterSource } from '../reducers/codekitten/register-reducer/register-source-actions.js';

import {setProjectJson} from "../reducers/codekitten/gui-reducer/gui-actions";


class WrapperGUIGetStartedComponent extends Component {
    constructor(props) {
        super(props);

    }
    componentWillMount() {

    }
    componentDidMount() {
        var projectJson = '';
        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.projectJson !== 'undefined') {
            projectJson = this.props.location.state.projectJson;
        }
 
        if (this.props.token !== '') {
            //tu man tutorial
            if (typeof projectJson !== 'undefined' && projectJson !== '') {
                this.props.creatProject('Dự án chưa đặt tên', JSON.parse(projectJson));
            } else {
                this.props.creatProject('Dự án chưa đặt tên');
            }
        }

        const QueryParams = new URLSearchParams(this.props.location.search); 
        const utm_source = QueryParams.get('utm_source');
        if(typeof utm_source !== "undefined" && utm_source !== "" && utm_source !== null) {
            this.props.setRegisterSource(utm_source);
            console.log(utm_source)
        }
    }
    componentDidUpdate(prevProps) {
        //gọi khi đăng nhập bên trong editor
        
        if(this.props.history.action==='POP'&&this.props.history.length>2){
            this.props.history.goBack();
        }
     
        if (this.props.token !== '' && this.props.projectJson !== '') {
           
            this.props.creatProject('Dự án chưa đặt tên', JSON.parse(this.props.projectJson));
            this.props.setProjectJson('');
        }
    }
    componentWillUnmount() {
        setTimeout(function () {
           window.location.reload();
        }, 1);
    }

    render() {
        var { isLoading, error, project } = this.props
         
        if (this.props.token !== '') {
             
            if (!isLoading && project != null) {
                var queryString = '';
                //tu man turorial
                if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.tutorial !== 'undefined') {
                    queryString = this.props.location.state.tutorial;
                }
                return <Redirect to={`/projects/${project.id}/editor${queryString}`} />;
            } else {
                return <Loader style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%", width: "100%", backgroundColor: "#48ac92"
                }} className="TailSpin" type="Rings" color="#ffa31a" height={100} width={100} />;
            }
        } else  {
            return (
                this.props.children
            );
        }



    }

}
WrapperGUIGetStartedComponent.propTypes = {

}

const mapStateToProps = (state) => {


    return {
        token: state.auth.token,
        isAuthInEditor: state.auth.isAuthInEditor,
        isLoading: state.gui.loading,
        error: state.gui.error,
        project: state.gui.project,
        nameProject: state.gui.nameProject,
        projectJson: state.projectEditor.projectJson,


    }
};

const mapDispatchToProps = (dispatch) => {
    console.log('go to Dispatch');
    return {
        creatProject: (name, projectJson) => dispatch(creatProject(name, projectJson)),
        updateAuthInEditor:(isAuthInEditor)=> dispatch(updateAuthInEditor(isAuthInEditor)),
        setRegisterSource: (utm_source) => dispatch(setRegisterSource(utm_source)),
        setProjectJson:(value)=> dispatch(setProjectJson(value))
    };
}




export default connect(
    mapStateToProps, mapDispatchToProps
)(withRouter(WrapperGUIGetStartedComponent));
