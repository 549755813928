import React, { Component } from 'react';
import { Markup } from 'interweave';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <div className="c-block c-news-block">
                <div className="container">
                <div className="c-box">
                    <div className="c-box__title is-button">
                    <div className="c-box__title__name"><span className="is-small">Tin tức</span><br /><b>Blog</b></div>
                    <div className="c-box__title__btn"><a className="btn btn-lg btn-primary is-circle" href={process.env.BLOG_POSTS_JSON_URL} target="_blank"><b>Xem toàn bộ</b></a></div>
                    </div>
                    <div className="c-box__content">
                    <div className="c-news-grid">
                        <ul>
                            {this.props.news.map((blog, index) =>
                                (
                                    <li key={index}>
                                        <div className="b-grid">
                                        <div className="b-grid__img"><a href={blog.link} target="_blank">{typeof blog._embedded['wp:featuredmedia'] !== "undefined" ? <img src={ blog._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url} alt={blog.title.rendered} />: null}</a></div>
                                        <div className="b-grid__content">
                                            <div className="b-grid__row">
                                            <h3 className="b-grid__title"><a href={blog.link} target="_blank"><Markup content={blog.title.rendered}/></a></h3>
                                            </div>
                                        </div>
                                        </div>
                                    </li>
                                )
                            )}
                        
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                {/*end container*/}
            </div>
        );
    }
}

export default News;