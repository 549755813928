import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getProjectsOutStanding, getProjectsMultiPlayer, getProjectsMultiView, getThemes, getContests } from '../../reducers/codekitten/projects-featured-reducer/featured-actions.js';
import HomeTopic from '../../components/codekitten/home-topic/home-topic.jsx'
import ProjectBest from './component-featured/project-best.jsx';
import BestItemsLoading from './component-featured/items-best-loading.jsx';
import ErrorBest from './component-featured/error-best-items.jsx';
import ComponentBodyItems from './component-featured/component-body-items.jsx';
import ComponentBodyItemsLoading from './component-featured/body-items-loading.jsx';
import ComponentError from './component-featured/error-items.jsx';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx';
class ProjectFeatured extends React.Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.projectsOutStanding == null || this.props.projectsOutStanding.length == 0)
            this.props.getProjectsOutStanding()
        if (this.props.projectsMultiPlayer == null || this.props.projectsMultiPlayer.length == 0)
            this.props.getProjectsMultiPlayer()
        if (this.props.projectsMultiView == null || this.props.projectsMultiView.length == 0)
            this.props.getProjectsMultiView()
        //theme
        if (this.props.themes == null || this.props.themes.length == 0)
            this.props.getThemes()
        //contests
        // if (this.props.contests == null || this.props.contests.length == 0)
        //     this.props.getContests()
    }
    render() {
        const {
            loadingOutStanding,
            loadingMultiPlayer,
            loadingMultiView,
            loadingThemes,
            loadingContests,

            themes,
            contests,
            projectsOutStanding,
            projectsMultiPlayer,
            projectsMultiView,
            errorOutStanding,
            errorMultiPlayer,
            errorMultiView,
            errorThemes,
            errorContests
        } = this.props;
        return (
            <>
                <SeoComponent title='Dự án'/>
                <HomeTopic name="Dự án"></HomeTopic>

                <div className="l-content">
                    <div className="container">
                        <div className="c-box is-margin-medium">
                            {!loadingOutStanding && typeof projectsOutStanding !== 'undefined' && projectsOutStanding.length > 0 ? (
                                <ProjectBest projects={projectsOutStanding} />
                            ) : (
                                <BestItemsLoading />
                            )}
                        </div>


                        {!loadingMultiPlayer && typeof projectsMultiPlayer !== 'undefined' && projectsMultiPlayer.length > 0 ? (
                            <ComponentBodyItems
                                projects={projectsMultiPlayer}
                                linkPath='/list-project?order_by=play'
                                title={'Nhiều người chơi'} orderBy={'Play'} />
                        ) : (
                            <ComponentBodyItemsLoading />
                        )}


                        {!loadingMultiView && typeof projectsMultiView !== 'undefined' && projectsMultiView.length > 0 ? (
                            <ComponentBodyItems
                                projects={projectsMultiView}
                                linkPath='/list-project?order_by=view'
                                title={'Nhiều người xem'} orderBy={'View'} />
                        ) : (
                            <ComponentBodyItemsLoading />
                        )}

                        {!loadingThemes && typeof themes !== 'undefined' && themes.length > 0 ? (

                            themes.map((theme) => {
                                
                                return typeof theme.projects!='undefined'&&theme.projects.length>0?(<ComponentBodyItems
                                    projects={theme.projects}
                                    linkPath={`/list-project?theme=${theme.id}`}
                                    title={theme.name}
                                />):null;
                            })

                        ) : (
                            <ComponentBodyItemsLoading />
                        )}

                        {/* {!loadingContests && contests.length > 0 ? (
                            contests.map((contest) => {
                                <ComponentBodyItems
                                    projects={contest.projects}
                                    linkPath={`/list-project?contest=${contest.id}`}
                                   
                                    title={contest.name}  />
                            })

                        ) : (
                            <ComponentBodyItemsLoading />
                        )} */}
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    loadingOutStanding: state.featured.loadingOutStanding,
    projectsOutStanding: state.featured.projectsOutStanding,
    errorOutStanding: state.featured.errorOutStanding,

    loadingMultiPlayer: state.featured.loadingMultiPlayer,
    projectsMultiPlayer: state.featured.projectsMultiPlayer,
    errorMultiPlayer: state.featured.errorMultiPlayer,

    loadingMultiView: state.featured.loadingMultiView,
    projectsMultiView: state.featured.projectsMultiView,
    errorMultiView: state.featured.errorMultiView,
    //theme
    loadingThemes: state.featured.loadingThemes,
    themes: state.featured.themes,
    errorThemes: state.featured.errorThemes,
    //contest
    loadingContests: state.featured.loadingContests,
    contests: state.featured.contests,
    errorContests: state.featured.errorContests,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectsOutStanding: () => dispatch(getProjectsOutStanding()),
        getProjectsMultiPlayer: () => dispatch(getProjectsMultiPlayer()),
        getProjectsMultiView: () => dispatch(getProjectsMultiView()),
        getThemes: () => dispatch(getThemes()),
        getContests: () => dispatch(getContests())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectFeatured));
