import React, { Component } from 'react'
import HighlightProjectItem from "../../../components/codekitten/highlight-projects/highlight-project-item.jsx"
import { withRouter } from 'react-router-dom';
class HighlightProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="c-block c-pupil-block">
                <div className="container">
                    <div className="c-box is-center">
                        <div className="c-box__title">
                            <div className="c-box__title__name"><span className="is-small">Sản phẩm</span><br /><b>Của học sinh</b></div>
                        </div>
                        <div className="c-box__content">
                            <div className="c-pupil-box">
                                <div className="c-pupil-box__inner">
                                    <div className="c-pupil-box__title"><b className="text-danger is-big">20.000+ </b>
                                        dự án tiêu biểu
                                    </div>
                                    <div className="c-pupil-slider">
                                        {this.props.projects.length > 0 ?
                                            <div className="owl-carousel js-pupil-slider">
                                                {this.props.projects.map((value, index) =>
                                                    <HighlightProjectItem
                                                        key={index}
                                                        title={value.project.name}
                                                        author={value.author.full_name}
                                                        desc={value.project.description}
                                                        srcImage={value.project.thumbnail}
                                                        onClick={() => {
                                                            this.props.history.push(`/projects/${value.project.id}`);
                                                        }}
                                                    />
                                                )}
                                            </div> : <div className="d-flex justify-content-center">Hiện chưa có dữ liệu</div>
                                        }

                                    </div>
                                    <div className="c-pupil-box__btn"><a className="btn btn-lg btn-primary is-circle" onClick={() => {
                                        this.props.history.push(`/list-project?featured=${process.env.FEATURED_QUERY}`);
                                    }}>
                                        Xem toàn bộ
                                        <b> Sản phẩm</b></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*end container*/}
            </div>
        )
    }
}
export default withRouter(HighlightProjects);