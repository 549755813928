import React, { Component } from 'react';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ArticleComponent extends Component {
    constructor(props) {
        super(props);
    }

 

    render() {
        return (
            <div className="b-grid"> 
                <div className="b-grid__img"><a 
                style={{backgroundImage: this.props.thumbnail === "" || this.props.thumbnail == null ? null : `url(${this.props.thumbnail})`,
                cursor: "pointer"}} 
                onClick={this.props.onClick}></a></div>
                <div className="b-grid__content">
                <div className="b-grid__row">
                    <h3 className="b-grid__title"><a  style={{cursor: "pointer"}} onClick={this.props.onClick}>{this.props.title}</a></h3>
                </div>
                <div className="b-grid__row b-grid__desc"><Markup content={this.props.description} /> </div>
                </div>
            </div>
        );
    }
}
ArticleComponent.protoTypes={
    onClick:PropTypes.func
}

export default ArticleComponent;