exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header-component_header_iuMXe {\n    position: fixed;\n    z-index: 999;\n    height: 100px;\n    width: 100%;\n    padding-left: 10%;\n    padding-right: 10%;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    background-color: white;\n    -webkit-box-shadow: -3px -5px 27px 4px rgba(0, 0, 0, 0.19);\n            box-shadow: -3px -5px 27px 4px rgba(0, 0, 0, 0.19);\n}\n.header-component_logo_2aaMQ {\n    height: 40px;\n}", ""]);

// exports
exports.locals = {
	"header": "header-component_header_iuMXe",
	"logo": "header-component_logo_2aaMQ"
};