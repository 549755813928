
import { compose } from 'redux';
import AppStateHOC from '../lib/app-state-hoc.jsx';
import GUI from '../containers/gui.jsx';
import HashParserHOC from '../lib/hash-parser-hoc.jsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
 import styles from './index.css';
import { connect } from 'react-redux';
import guiApi from '../api/gui-api';
const WrappedGui = compose(
    AppStateHOC,
    HashParserHOC
)(GUI);
class WrapperGUIComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSave:false
        }
       
    }

    componentDidMount(){
       
        guiApi.getProjectDetail(this.props.match.params.projectId).then(res=>{
            if(res.data.creator.id===this.props.idUser){
                this.setState({canSave:true});
            }
        })
    }
  
    componentDidUpdate(prevProps){
   
    }
    componentWillUnmount(){
      
    }
    render() {
        
        return (
         
           <WrappedGui
              assetHost={`${process.env.BASE_URL}/api/v1/codekitten/assets`}
                canSave={this.state.canSave}
                projectHost={`${process.env.BASE_URL}/api/v1/codekitten/projects`}
                projectId={`${this.props.match.params.projectId}`}
                canEditTitle
                isStarted={false}

            />
        );
    }

}
const mapStateToProps = (state) => ({
    idUser:state.auth.id,
    
})
const mapDispatchToProps = (dispatch) => ({

})


export default connect(mapStateToProps, mapDispatchToProps) (withRouter(WrapperGUIComponent));
