import {
    LIST_PROJECTS_REQUEST, LIST_PROJECTS_SUCCESS, LIST_PROJECTS_ERROR,SET_CURRENT_PAGE,SET_TITLE_LIST_PROJECT,RESET_STATE_LIST_PROJECT
    
} from '../constants/constants';
import projectsApi from '../../../api/projects-api';


export const getListProject = (params) => (dispatch) => {
    dispatch(getListProjectRequest());
    projectsApi.getProjects(params).then(res => {
        
        console.log(res);
        dispatch(getListProjectSuccess(res.data.projects, res.data.total_page));
    }).catch(err => {
        dispatch(getListProjectFailed(err));
    });
}

export const setCurrentPage=(currentPage)=> (dispatch) => {
    dispatch({
        type: SET_CURRENT_PAGE,
        data:{currentPage}
    })
}
export const setTitle=(title)=> (dispatch) => {
    dispatch({
        type:SET_TITLE_LIST_PROJECT,
        data:{title}
    })
}
export const resetStateListProject=()=> (dispatch) => {
    dispatch({
        type:RESET_STATE_LIST_PROJECT
    })
}


export const getListProjectRequest = () => ({
    type: LIST_PROJECTS_REQUEST
});

export const getListProjectSuccess = (projects, totalPage ) => ({
    type: LIST_PROJECTS_SUCCESS,
    data:
        { projects, totalPage  }
    ,
});

export const getListProjectFailed = (errorListPlayer) => ({
    type: LIST_PROJECTS_ERROR,
    data: { errorListPlayer }
});

