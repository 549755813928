import React, { Component } from 'react';
import ComponentItems from "./component-items.jsx";
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {resetStateListProject} from '../../../reducers/codekitten/list-projects-reducer/list-projects-actions';
import {store} from '../../../reducers/codekitten/store';
class ComponentBodyItems extends Component {
    constructor(props) {
        super(props);
        this.gotoStartProject = this.gotoStartProject.bind(this);
        this.onClickViewAll=this.onClickViewAll.bind(this);
    }
    gotoStartProject() {
        console.log("gotoStartProject");
    }
    onClickViewAll(){
        store.dispatch(resetStateListProject());
        this.props.history.push(this.props.linkPath);
    }
 
    

    render() {
        return (
            <div className="c-box is-margin-medium">
                <div className="c-box__title is-button">
                    <h2 className="c-box__title__name">
                        <span className="is-small">Dự án</span>
                        <br />
                        <b>
                            {this.props.title}
                        </b>
                    </h2>
                    <div className="c-box__title__btn">
                        <a className="btn btn-primary is-circle" onClick={this.onClickViewAll}>
                            <b>Xem toàn bộ</b>
                        </a>
                    </div>
                </div>
                <div className="c-box__content">
                    <div className="c-project-grid">
                        <ul>
                            {
                                this.props.projects.map((value, index) =>
                                    <ComponentItems
                                        key={index}
                                        projectId={value.project.id}
                                        userId={value.author.id}
                                        userCode={value.project.user_code}
                                        name={value.project.name}
                                        thumbnail={value.project.thumbnail}
                                        fullname={value.author.full_name}
                                        avatar={value.author.avatar}
                                    />)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
ComponentBodyItems.propTypes = {
    
    linkPath: PropTypes.string,
};

export default withRouter(ComponentBodyItems);