import React, { Component } from 'react';
import ChallengingComponent from '../../components/codekitten/challenges/challenging-component.jsx';
import ChallengesPrivate from "../../lib/libraries/challenge/challenges-detail/privates-challenge-boxes.json";
import ChallengesTreasure from "../../lib/libraries/challenge/challenges-detail/treasure_challenge_boxes.json";

import challenges from "../../lib/libraries/challenge/challenges-list.json"; // cái này để lấy danh sách các challenges
import NotFound from '../not-found/not-found.jsx';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx';
import { withRouter } from 'react-router-dom';
class Challenging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            challenges_url: this.props.match.params.challenges_url,
            challenge: {},
        }
    }
    componentDidMount() {
        window.scroll(0, 0);
        window.scroll(0, 0);
        for (const e of challenges) {
            if (e.url === this.state.challenges_url)
                this.setState({ loading: false, challenge: e })
        }
        this.setState({ loading: false })
    }

    render() {
        if (typeof this.state.challenge.stages === "undefined" && !this.state.loading) {
            return <NotFound />
        }
        return (

            <div>
               {this.state.loading ? null: <SeoComponent 
               
               description={this.state.challenge.description}
               title={this.state.challenge.name} image={this.state.challenge.thumbnail}/>}
                {this.state.loading ? null :
                    <ChallengingComponent
                        map={this.state.challenge.map}
                        Challenges={this.state.challenge.stages}

                        challenge_name={"challenging-pirates"}></ChallengingComponent>}
            </div>
        );
    }
}

export default withRouter(Challenging);
