import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    totalPage: PropTypes.number,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
    pageSize: 16
}

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    componentWillMount() {
        // set page if totalPage array isn't empty
        if (this.props.totalPage) {
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if totalPage array has changed
        if (this.props.totalPage !== prevProps.totalPage) {
            this.setPage(this.props.initialPage);
        }
    }

    setPage(page) {
        var { totalPage, pageSize } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages || totalPage === 1) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(totalPage, page, pageSize);

        // get new page of totalPage from totalPage array
        // var pageOfItems = totalPage.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({ pager: pager });

        // call change page function in parent component send page to parent
        this.props.onChangePage(pager.currentPage);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = totalItems;

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = Array.from({ length: (endPage + 1) - startPage }, (value, i) => startPage + i);
        //  var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }
    
        // <li className="page-item"><a className="page-link" href="#">1</a></li>
        // <li className="page-item active"><span className="page-link">2</span></li>
        // <li className="page-item"><a className="page-link" href="#">3</a></li>



        return (

            <div className="b-page">
                <ul className="pagination justify-content-center">
                    <li className={pager.currentPage === 1 ? 'page-item' : ''}>
                        <span style={{cursor: "pointer"}} className="page-link" onClick={() => this.setPage(1)}>First</span>
                    </li>
                    <li className={pager.currentPage === 1 ? 'page-item' : ''}>
                    <span style={{cursor: "pointer"}} className="page-link" onClick={() => this.setPage(pager.currentPage - 1)}>Previous</span>
                    </li>

                  
                        {pager.pages.map((page, index) =>
                            <li key={index} className={pager.currentPage === page ? 'page-item active' : 'page-item'}>

                                <span style={{cursor: "pointer"}} onClick={() => this.setPage(page)} className="page-link">
                                    {page}
                                </span>
                            </li>
                        )}

                  

                    <li className={pager.currentPage === pager.totalPages ? 'page-item' : ''}>
                    <span style={{cursor: "pointer"}} className="page-link" onClick={() => this.setPage(pager.currentPage + 1)}>Next</span>
                    </li>
                    <li className={pager.currentPage === pager.totalPages ? 'page-item' : ''}>
                    <span style={{cursor: "pointer"}} className="page-link" onClick={() => {

                            this.setPage(pager.totalPages)
                        }

                        }>Last</span>
                    </li>
                </ul>
            </div>
        );
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;