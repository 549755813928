import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react'
import { getActiveContests } from '../../reducers/codekitten/contest-reducer/contest-actions'
import styles from './join-contest-dialog.css'
import { connect } from 'react-redux'
import projectsApi from '../../api/projects-api';
import { withRouter } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

class JoinContestDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedContestId: 0
        }
    }

    onChangeContest = (event) => {
        const name = event.target.name;
        const selectedIndex = event.target.options.selectedIndex;
        this.setState({ selectedContestId: selectedIndex })
    }

    formatDate(date) {
        if (date == null || typeof date === "undefined") return "..."
        let year = date.substring(0, 4);
        let month = date.substring(5, 7)
        let day = date.substring(8, 10)
        return day + "/" + month + "/" + year;
    }

    componentDidMount() {

        this.props.getActiveContests();
    }

    submitProject = () => {
        toast.loading('Waiting...');

        this.props.handleClose()
        console.log("AAAAAAAAAAA")
        projectsApi.submitProjectToContest(this.props.projectId, this.props.activeContests[this.state.selectedContestId].id, this.props.token).then(response => {
            toast.dismiss();
            if (response.message.status_code == 200) {
                toast.success("Dự án tham gia cuộc thi thành công")
            } else {
                if (response.message.status_code == 201)
                    toast.error("Dự án đã được tham gia cuộc thi!")
            }
        }).catch(error => {
            console.log(error)
            toast.dismiss();
            toast.error("không nộp đc bài, đã có lỗi xảy ra!")
        })

    }
    render() {
        const { activeContests, loadingActiveContests } = this.props;
        return (
            <>
                <Toaster position="top-center"
                />
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="join-contest-dialog"
                    PaperProps={{
                        style: { borderRadius: '10px' }
                    }}
                >
                    <DialogTitle id="join-contest-dialog">
                        <h3 style={{ width: "80%" }}>Tham gia cuộc thi
                            <Button onClick={this.props.handleClose} className={styles.closeContestDialogBtn} color="info">
                                <CloseIcon />
                            </Button>
                        </h3>
                    </DialogTitle>
                    <DialogContent>
                        {loadingActiveContests ? null :
                            <>
                                {activeContests.length == 0 ?
                                    <div style={{ display: 'flex' }}>
                                        <div >
                                            <img style={{ width: "200px" }} src="/static/upload/404.png" alt={404} />
                                        </div>
                                        <h4 style={{ margin: 'auto' }}>
                                            Hiện tại chưa có cuộc thi để tham gia
                                        </h4>
                                    </div> :
                                    <div>
                                        <div className="form-group">
                                            <h6>Chọn cuộc thi tham gia:</h6>
                                            <select name="contest" className={styles.contest_input_field} id="contest" onChange={this.onChangeContest}>
                                                {activeContests.map((contest) => (
                                                    <option key={contest.id}>{contest.name}</option>
                                                ))}
                                            </select>
                                            <h6>Ngày bắt đầu: {this.formatDate(activeContests[this.state.selectedContestId].start_date)}</h6>
                                            <h6>Ngày kết thúc: {this.formatDate(activeContests[this.state.selectedContestId].end_date)}</h6>
                                        </div>
                                        <button onClick={this.submitProject} className="btn btn-primary">Xác nhận tham gia</button>
                                    </div>}</>}
                    </DialogContent>
                    <DialogActions></DialogActions>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = state => ({
    activeContests: state.contest.activeContests,
    loadingActiveContests: state.contest.loadingActiveContests,
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => {
    return {
        getActiveContests: () => dispatch(getActiveContests())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinContestDialog));