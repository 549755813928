import axiosClient from "./axios-client";
const featuredApi={
    getThemes:(active,featured,page,perPage)=>{
        return axiosClient.get('/api/v1/codekitten/themes',{
            params:{
                active,
                featured,
                page,
                per_page:perPage,

            }
        })
    },
    getContests:(active,featured,page,perPage)=>{
        return axiosClient.get('/api/v1/codekitten/contests',{
            params:{
                active,
                featured,
                page,
                per_page:perPage,

            }
        })
    },
}
export default featuredApi;