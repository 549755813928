import axiosClient from "./axios-client";

const contestApi = {
    getActiveContestList: () => {
        const url="/api/v1/project/contests";
        return axiosClient.get(url, 
            {
                active: 1
            })
    }
}

export default contestApi;