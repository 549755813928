
import React from "react";
import HighlightProjects from "./home-components/highlight-projects.jsx";
import { withRouter } from "react-router-dom";
import { getNewsFeatured, getProjectsFeatured } from '../../reducers/codekitten/home-reducer/home-actions.js';
import Teachers from "./home-components/teachers.jsx";
import { connect } from 'react-redux';
import Combatants from "./home-components/combatants.jsx";
import Stars from "./home-components/stars.jsx";
import News from "./home-components/news.jsx";
import HomeHead from "./home-components/home-head.jsx";
import HomeChallenge from "./home-components/home-challenge.jsx";
import HomeAbout from "./home-components/home-about.jsx";
import HomeKnowledge from "./home-components/home-knowledge.jsx";
import HomeTry from "./home-components/home-try.jsx";
import HomeInspired from "./home-components/home-inspired.jsx";
import SeoComponent from "../../components/codekitten/seo/seo-component.jsx";
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.onClickGetStarted = this.onClickGetStarted.bind(this);
    }

    componentDidUpdate(prevProps) {
        window['updateslide']();
        window['updatecombatant']();
        window['starslide']();
    }

    onClickGetStarted() {
        this.props.history.push('/register')
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getProjectsFeatured();
        if (typeof (this.props.news) !== "undefined" && this.props.news.length == 0)
            this.props.getNewsFeatured();
    }

    render() {
        const { loadingProjects, loadingNews, projectsError, newsError, projects, news } = this.props;
        return (
            <>
            <SeoComponent/>
                <HomeHead></HomeHead>
                <HomeChallenge></HomeChallenge>
                <HomeAbout></HomeAbout>
                <HomeKnowledge></HomeKnowledge>
                <Teachers></Teachers>
                <HomeTry></HomeTry>
                <Combatants></Combatants>
                <Stars></Stars>
                <HomeInspired></HomeInspired>
                { projectsError === "Has Error!" || projects == null ? null :
                <HighlightProjects projects={projects}></HighlightProjects>
                }
                {/* { newsError === "Has Error!" || news == null ? null :
                <News news={news}></News>
                } */}
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    loadingProjects: state.home.loadingProjects,
    loadingNews: state.home.loadingNews,
    projectsError: state.home.projectsError,
    newsError: state.home.newsError,
    projects: state.home.projects,
    news: state.home.news
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectsFeatured: () => dispatch(getProjectsFeatured()),
        getNewsFeatured: () => dispatch(getNewsFeatured())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
