
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DefaultAvatar from "../../../components/codekitten/default-avatar/default-avatar.jsx";
import styles from "../profile-page.css";
import EditIcon from '@material-ui/icons/Edit';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import UpdateUserAvatar from './update-user-avatar.jsx';
import UpdateUserInfo from './update-user-info.jsx';
import { backToHomepage, toUpdateAvatar, toUpdateInfo, getDVHC } from '../../../reducers/codekitten/profile-reducer/userprofile-actions.js';

class UserProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            districts: [],
            currentProvinceCode : ""
        }
    }

    componentDidMount() {
        if (this.props.dvhc === null) this.props.getDVHC();
        this.props.backToHomepage()
    }

    formatDate(date) {
        if(date == null || typeof date === "undefined") return "..."
        let year = date.substring(0, 4);
        let month = date.substring(5, 7)
        let day = date.substring(8, 10)
        return day + "/" + month + "/" + year;
    }

    formatProvinceCode(code, provinces) {
        if (provinces !== null) {
            for (let i = 0; i < provinces.length; i++) {
                if (provinces[i].code === code) {
                    console.log("province " + provinces[i])
                    if(this.state.districts.length == 0 || this.state.currentProvinceCode !== code) this.setState({currentProvinceCode: code, districts: provinces[i].districts})
                    return provinces[i].name;
                }
            }
        }
        return "...";
    }

    formatDistrictCode(code, districts) {
        if (districts !== null) {
            for (let i = 0; i < districts.length; i++) {
                if (districts[i].code === code) {
                    return districts[i].name;
                }
            }
        }
        return "...";
    }

    formatSchoolCode(code, schools) {
        if(schools !== null) {
            for(let i = 0; i < schools.length; i++) {
                if(schools[i].code === code) 
                return schools[i].name;
            }
        }
        return "...";
    }

    render() {
        const { loading, avatar, projectsAuthorName, isAuthor, bio, country, province, district, school, birthday, isUpdatingAvatar, isUpdatingInfo, dvhc } = this.props;
        return (
            <>
                {!isUpdatingInfo ?
                    (<div className="c-info-box">
                        <div className="c-info-box__title">Thông tin cá nhân&nbsp;
                            {isAuthor ? <small className={styles.small_edit}><a className={styles.hoverText}
                                onClick={() => { if (dvhc !== null) this.props.toUpdateInfo() }}><EditIcon fontSize='inherit' />
                                Cập nhật thông tin</a></small> : null}
                        </div>

                        <div className="c-info-box__content">
                            <div className="row">
                                <div className="col-md-6 pr-md-5">
                                    {loading ? <Loader className="TailSpin" type="Rings" color="#ffa31a" height={100} width={100} /> :
                                        (<div className="b-grid mb-3">
                                            <div className="b-grid__img">
                                                <figure><DefaultAvatar fullname={projectsAuthorName} avatar={avatar} /></figure>
                                            </div>
                                            <div className="b-grid__content">
                                                <div className="b-grid__row">
                                                    <h1 className="b-grid__title">{projectsAuthorName}</h1>
                                                    <div className="b-grid__row">
                                                        {isAuthor ? <p><a className={styles.hoverText} onClick={() => this.props.toUpdateAvatar()}><EditIcon fontSize='inherit' /> Cập nhật ảnh đại diện</a></p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}

                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Giới thiệu về tôi</h5>
                                        </div>


                                        <div className="card-body">
                                            <p>{!loading && bio !== null && bio !== "" ? bio : "..."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-md-3 pr-md-5">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">Ngày Sinh:
                                            <div className="float-right">{!loading && birthday !== null && birthday !== "" ? this.formatDate(birthday) : "..."}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Quốc gia:
                                            <div className="float-right">{!loading && country !== null && country !== "" ? country : "Việt Nam"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Tỉnh/Thành phố:
                                            <div className="float-right">{!loading && province !== null && province !== "" ? this.formatProvinceCode(province, this.props.dvhc) : "..."}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Quận/Huyện:
                                            <div className="float-right">{!loading && district !== null && district !== "" ? this.formatDistrictCode(district, this.state.districts) : "..."}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Trường học:
                                            <div className="float-right">{!loading && school !== null && school.name !== "" ? school.name : "..."}</div>
                                        </li>
                                        <li className="list-group-item">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>)
                    : <UpdateUserInfo backToHomepage={this.props.backToHomepage} fullname={projectsAuthorName} avatar={avatar}
                        bio={bio} country={"Việt Nam"} provinceName={this.formatProvinceCode(province, this.props.dvhc)} province={province}
                        districts={this.state.districts} districtName={this.formatDistrictCode(district, this.state.districts)} district={district}
                        school={school.code} schoolName={school.name}
                        birthday={birthday} />}

                {isUpdatingAvatar ? // model update avatar
                    (<UpdateUserAvatar backToHomepage={this.props.backToHomepage} avatar={avatar} projectsAuthorName={projectsAuthorName} />)
                    : null}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    bio: state.profile.bio,
    country: state.profile.country,
    province: state.profile.province,
    district: state.profile.district,
    school: state.profile.school,
    birthday: state.profile.birthday,
    isUpdatingInfo: state.profile.isUpdatingInfo,
    isUpdatingAvatar: state.profile.isUpdatingAvatar,
    dvhc: state.profile.dvhc
})

const mapDispatchToProps = (dispatch) => {
    return {
        backToHomepage: () => dispatch(backToHomepage()),
        toUpdateInfo: () => dispatch(toUpdateInfo()),
        toUpdateAvatar: () => dispatch(toUpdateAvatar()),
        getDVHC: () => dispatch(getDVHC())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);