import {
    FEATURED_OUTSTANDING_REQUEST, FEATURED_OUTSTANDING_SUCCESS, FEATURED_OUTSTANDING_ERROR,
    FEATURED_MULTIPLAYER_REQUEST, FEATURED_MULTIPLAYER_SUCCESS, FEATURED_MULTIPLAYER_ERROR,
    FEATURED_MULTIVIEW_REQUEST, FEATURED_MULTIVIEW_SUCCESS, FEATURED_MULTIVIEW_ERROR,
    FEATURED_THEMES_REQUEST, FEATURED_THEMES_SUCCESS, FEATURED_THEMES_ERROR,
    FEATURED_CONTESTS_REQUEST, FEATURED_CONTESTS_SUCCESS, FEATURED_CONTESTS_ERROR
} from '../constants/constants';
import projectsApi from '../../../api/projects-api';
import featuredApi from '../../../api/featured-api';
import homeApi from '../../../api/home-api';

export const getProjectsOutStanding = () => (dispatch) => {
    dispatch(getProjectsOutStandingRequest());
    //dự án nổi bật
    projectsApi.getProjects({
        page: 1,
        per_page: 5,
        featured: process.env.FEATURED_QUERY
    }).then(res => {
        dispatch(getProjectsOutStandingSuccess(res.data.projects));
    }).catch(err => {
        dispatch(getProjectsOutStandingFailed(err));
    });
}

export const getProjectsMultiPlayer = () => (dispatch) => {
    dispatch(getProjectsMultiPlayerRequest());
    //dự án nhiều người chơi
    projectsApi.getProjects({
        page: 1,
        per_page: 4,
        // order_by: 'play',
        featured: process.env.FEATURED_QUERY
    }).then(res => {
        dispatch(getProjectsMultiPlayerSuccess(res.data.projects));
    }).catch(err => {
        dispatch(getProjectsMultiPlayerFailed(err));
    });
}

export const getProjectsMultiView = () => (dispatch) => {
    dispatch(getProjectsMultiViewRequest());
    //dự án nhiều người xem
    projectsApi.getProjects({
        page: 1,
        per_page: 4,
        // order_by:'view',
        featured: process.env.FEATURED_QUERY
    }).then(res => {
        dispatch(getProjectsMultiViewSuccess(res.data.projects));
    }).catch(err => {
        dispatch(getProjectsMultiViewFailed(err));
    });
}
//1
export const getThemes = () => async (dispatch) => {
    dispatch(getThemesRequest());

    let resTheme={};
    try {
        //Lấy tất cả chủ đề nổi bật 5 theme /1 trang
        resTheme = await featuredApi.getThemes(1, 1, 1, process.env.MAX_THEME);

    } catch (error) {
        console.log(error);
    }

    if (typeof resTheme.data !== 'undefined' && typeof resTheme.data.themes !== 'undefined' && resTheme.data.themes.length > 0) {
        //dự án theo chủ đề
        let themes=resTheme.data.themes;

        for (var index=0; index<themes.length; index++) {
            let resProjects= await projectsApi.getProjects({
                page: 1,
                per_page: 4,
                theme: themes[index].id
            });
            themes[index].projects=resProjects.data.projects;
           
        }
        dispatch(getThemesSuccess(themes));
        // resTheme.data.themes.map(async (theme,index,arr)=>{
        //     try {
               
        //        if(index===arr.length-1){
        //         dispatch(getThemesSuccess(themes));
        //        } 
        //     } catch (error) {
        //       console.log(error);  
        //     }
        // });
       
        

    } else {
        dispatch(getThemesFailed('Không có chủ đề'));
    }

}
//2
export const getContests = () => async (dispatch) => {
    dispatch(getContestsRequest());

    let resContest={};
    try {
        //Lấy tất cả cuộc thi nổi bật 5 cuộc thi /1 trang
        resContest = await featuredApi.getContests(1, 1, 1, 5);

    } catch (error) {
        console.log(error);
    }

    if (typeof resContest.data !== 'undefined' && typeof resContest.data.contests !== 'undefined' && resContest.data.contests.length > 0) {
        let contests=resTheme.data.contests;
        //dự án theo cuộc thi
        for (var index=0; index<contests.length; index++) {
            let resProjects= await projectsApi.getProjects({
                page: 1,
                per_page: 4,
                contest: contests[index].id
            });
            contests[index]["projects"]=resProjects.data.projects;
        }
        dispatch(getContestsSuccess(contests));
       
        
       

    } else {
        dispatch(getContestsFailed('Không có cuộc thi'));
    }

}

export const getProjectsOutStandingRequest = () => ({
    type: FEATURED_OUTSTANDING_REQUEST
});

export const getProjectsOutStandingSuccess = (projectsOutStanding) => ({
    type: FEATURED_OUTSTANDING_SUCCESS,
    data:
        { projectsOutStanding }
    ,
});

export const getProjectsOutStandingFailed = (errorOutStanding) => ({
    type: FEATURED_OUTSTANDING_ERROR,
    data: { errorOutStanding }
});

export const getProjectsMultiPlayerRequest = () => ({
    type: FEATURED_MULTIPLAYER_REQUEST
});

export const getProjectsMultiPlayerSuccess = (projectsMultiPlayer) => ({
    type: FEATURED_MULTIPLAYER_SUCCESS,
    data:
        { projectsMultiPlayer }
    ,
});

export const getProjectsMultiPlayerFailed = (errorMultiPlayer) => ({
    type: FEATURED_MULTIPLAYER_ERROR,
    data: { errorMultiPlayer }
});

export const getProjectsMultiViewRequest = () => ({
    type: FEATURED_MULTIVIEW_REQUEST
});

export const getProjectsMultiViewSuccess = (projectsMultiView) => ({
    type: FEATURED_MULTIVIEW_SUCCESS,
    data:
        { projectsMultiView }
    ,
});

export const getProjectsMultiViewFailed = (errorMultiView) => ({
    type: FEATURED_MULTIVIEW_ERROR,
    data: { errorMultiView }
});

//1

export const getThemesRequest = () => ({
    type: FEATURED_THEMES_REQUEST
});

export const getThemesSuccess = (themes) => ({
    type: FEATURED_THEMES_SUCCESS,
    data:
        { themes }
    ,
});

export const getThemesFailed = (errorThemes) => ({
    type: FEATURED_THEMES_ERROR,
    data: { errorThemes }
});

//2
export const getContestsRequest = () => ({
    type: FEATURED_CONTESTS_REQUEST
});

export const getContestsSuccess = (contests) => ({
    type: FEATURED_CONTESTS_SUCCESS,
    data:
        { contests }
    ,
});

export const getContestsFailed = (errorContests) => ({
    type: FEATURED_CONTESTS_ERROR,
    data: { errorContests }
});