import React, { Component } from 'react';
import Star from "./star-component.jsx";

import StarList from "../../../../src/lib/libraries/homepage/stars.json";

class Stars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stars: []
        }
    }
    
    componentDidMount() {
        this.setState({
            stars: StarList
        })
    }

    render() {
        return (
        <div className="c-block c-star-block">
          <div className="container">
            <div className="c-box">
              <div className="c-box__title">
                <div className="c-box__title__name"><span className="is-small">Codekitten</span><br /><b>Ngôi sao</b></div>
              </div>
              <div className="c-box__content">
                    <div className="c-star-slider">
                        <div className="owl-carousel js-star-slider">
                            {this.state.stars.map((star, index)=> (
                                <Star key={index} name={star.full_name} link_img={star.link_img} age={star.age}
                                school={star.school} short_description={star.short_description}></Star>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
          </div>
          {/*end container*/}
        </div>
        );
    }
}

export default Stars;