import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from "./dialog-styles.css";
export default class AlertDialog extends Component {
    render() {
        return (
            <>
                <Dialog
                    open={this.props.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle disableTypography={true} className={styles.dialog_header} id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                    <DialogContentText className={styles.dialog_font_family} id="alert-dialog-description">
                        {this.props.description}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button className={styles.dialog_font_family} onClick={this.props.onClick} color="primary">
                        ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}
