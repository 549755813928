import { Link } from "react-router-dom"
import { connect } from 'react-redux';

import React, { Component } from 'react';

class CtaButton extends Component {
    render() {
        return (
            <div className="c-box__title__btn">
            <Link className="btn btn-lg btn-primary is-circle" to={`${this.props.loggedIn?'/projects/editor':"/register"}`}>
               {this.props.loggedIn?<b>Bắt đầu ngay</b>:<div><b>Đăng ký</b>  &nbsp;để bắt đầu</div>}
             
            </Link>
        </div>
        );
    }
}
const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn
    
});

export default connect(mapStateToProps, null)(CtaButton);
// const CtaButton = () => {
//     return (
//         <div className="c-box__title__btn">
//             <Link className="btn btn-lg btn-primary is-circle" to="/register">
//                 <b>Đăng ký</b>&nbsp;để bắt đầu
//             </Link>
//         </div>
//     )
// }

// export default CtaButton;